/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider--block {
    position: relative;
    padding-bottom: 25px;
    .info--block {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(475px, -12px);
    }
    @media screen and (max-width: 1199px) {
        padding-bottom: 0px;
        .info--block {
            right: 85px;
            transform: translate(0, -50%);
        }
    }
    @media screen and (max-width: 768px) {
        .info--block {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            transform: translate(0,0);
            margin: 25px auto;
        }
    }
}

.slider {
    opacity: 0;
    transition: 400ms;
	margin-bottom: 0px;
    &.slick-initialized {
        opacity: 1;
    }
	.slick-slide {
        height: 520px;
		overflow: hidden;
		position: relative;
		outline: none;
		img {
			display: block;
			width: 100%;
			height: 100%;
            object-fit: cover;
            object-position: center center;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			text-align: center;
			color: #fff;
		}
	}
    .slick-arrow {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        overflow: hidden;
        opacity: 0.8;
        background-color: rgba(65, 64, 64, 1);
        transition: 300ms;
        z-index: 1;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            background: url(../img/next.svg) no-repeat center center;
            background-size: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &.slick-prev {
            left: 50%;
            margin-left: -570px;
        }
        &.slick-next {
            right: 50%;
            margin-right: -570px;
            &:after {
                transform: rotate(-180deg);
            }
        }
        &:hover {
            opacity: 1;
        }
    }
    @media screen and (max-width: 1199px) {
        .slick-arrow {
            &.slick-prev {
                left: 15px;
                margin: 0;
            }
            &.slick-next {
                right: 15px;
                margin: 0;    
            }
        }
    }
    @media screen and (max-width: 991px) {
        .slick-arrow {
            width: 40px;
            height: 40px;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: auto;
        }
    }
}

.info--block {
    width: 320px;
    text-align: center;
    position: relative;
    background-color: #e8e8e8;
    border: 10px solid #fff;
    padding: 10px;
    &.gray {
        border: 10px solid #727271;
        &:before {
            border: 2px solid #727271;
        }
    }
    &:before {
        content: '';
        position: absolute;
        left: -12px;
        right: -12px;
        top: -12px;
        bottom: -12px;
        border: 2px solid #d1d1d1;
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 2px solid #d1d1d1;
    }
    * {
        position: relative;
        z-index: 1;
    }
    h5 {
        font-family: $callibri;
        font-size: 17px;
        color: #2b2a29;
        margin: 0 0 10px 0!important;
    }
    p {
        margin: 0;
    }
    .info--block__num {
        position: relative;
        padding: 5px 0;
        &:before {
            content: '';
            height: 20px;
            width: 100%;
            background-color: #292929;
            position: absolute;
            bottom: 13px;
            left: 0;
            right: 0;
            z-index: -1;
        }
        .icon {
            @include inline-block;
            width: 36px;
            height: 36px;
            background-color: #fff;
            border-radius: 100%;
            box-shadow: 0px 3px 5px #464646;
            padding: 8px;
            margin-right: 5px;
            margin-top: 0px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        a {
            font-family: $callibriB;
            font-size: 30px;
            color: #2b2a29;
            z-index: 2;
            span {
                @include inline-block;
                color: #fff;
                margin: 0 3px 8px;
            }
        }
        .city {
            display: table;
            position: relative;
            font-family: $bold;
            font-size: 15px;
            color: #fff;
            text-transform: uppercase;
            padding: 0 5px 10px;
            margin: 0 0 0 78px;
            line-height: 1;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: -50px;
                border-radius: 5px;
                background-color: #292929; 
                z-index: -1;
            }
        }
    }
    @media screen and (max-width: 420px) {
        width: 285px;
        h5 {
            font-size: 15px;
        }
        .info--block__num {
            padding: 0;
            &:before {
                height: 15px;
                bottom: 7px;
            }
            .icon {
                width: 29px;
                height: 29px;
                margin-top: -2px;
                margin-right: 3px;
            }
            a {
                font-size: 24px;
                span {
                }
            }
            .city {
                font-size: 12px;
                margin-left: 74px;
                margin-top: -5px;
                &:before {
                    top: -35px;
                    bottom: 1px;
                }
            }
        }
    }
}
/* main slider */

/* main catalog */
.cat {
    padding-bottom: 25px;
}
.title {
    position: relative;
    overflow: hidden;
    margin: 50px 0;
    &:before, &:after {
        content: '';
        width: 50%;
        height: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    &:before {
        background-color: #009846;
        left: 0;
    }
    &:after {
        background-color: #e31e24;
        right: 0;
    }
    > * {
        display: table;
        position: relative;
        padding: 0 125px;
        background-color: #fff;
        text-align: center;
        z-index: 1;
        margin: 0 auto;
        &:before, &:after {
            content: '';
            width: 25px;
            height: 25px;
            background: url(../img/icon20.png) no-repeat center center;
            background-size: contain;
            filter: grayscale(100%);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &:before {
            left: 55px;
        }
        &:after {
            right: 55px;
        }
    }
    @media screen and (max-width: 991px) {
        margin: 30px 0;
        > * {
            padding: 0px 75px; 
            &:after {
                right: 30px;
            }
            &:before {
                left: 30px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 0 15px;
        margin: 20px 0;
        > * {
            padding: 0 50px;
            &:after {
                width: 20px;
                height: 20px;
                right: 15px;
            }
            &:before {
                width: 20px;
                height: 20px;
                left: 15px;
            }
        }
    }
}
.cat__block {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .col {
        height: 200px;
        width: calc(16.66%);
        padding: 8px;
        opacity: 0.2;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center bottom;
        }
        &:hover {
            opacity: 1;
        }
    }
    .cat--title {
        position: absolute;
        font-family: $regular;
        font-size: 34px;
        text-transform: uppercase;
        color: #2b2a29;
        transform: rotate(-90deg);
        &.first {
            left: 0;
            top: 0;
            margin: 50px 0 0 -80px;
        }
        &.second {
            bottom: 0;
            right: 0;
            margin: 0 -75px 60px 0;
        } 
    }
    @media screen and (max-width: 1199px) {
        .col {
            height: 150px;
        }
        .cat--title {
            font-size: 26px;
            &.first {
                margin: 50px 0 0 -50px;
            }
            &.second {
                margin: 0 -40px 30px 0;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .col {
            height: 125px;
        }
    }
    @media screen and (max-width: 768px) {
        .col {
            padding: 15px;
        }
        .cat--title {
            width: 100%;
            position: relative;
            top: 0;
            transform: rotate(0deg);
            &.first,
            &.second {
                margin: 15px 0;
            }
        }
    }
    @media screen and (max-width: 580px) {
        .col {
            height: 100px;
            padding: 5px;
        }
    }
    @media screen and (max-width: 420px) {
        .col {
            height: 80px;
            padding: 5px;
        }
    }
}
/* main catalog */

/* main advantages */
.advantages__block {
    height: 540px;
    position: relative;
    margin: 60px 0;
    .advantages__block--col {
        width: 230px;
        height: 230px;
        position: absolute;
        border-radius: 100%;
        border: 2px solid #b2b4b3;
        overflow: hidden;
        &.large {
            width: 250px;
            height: 250px;
        }
        &.small {
            width: 165px;
            height: 165px;
        }
        &.col--1 {
            top: 140px;
            left: 120px;
            z-index: 1;
        }
        &.col--2 {
            top: 0;
            left: 240px;
        }
        &.col--3 {
            top: 125px;
            right: 60px;
            z-index: 1;
        }
        &.col--4 {
            top: 0;
            right: 220px;
        }
        &.col--5 {
            top: 10px;
            right: 0;
        }
        &.col--6 {
            bottom: 0;
            right: 205px;
        }
        &.col--7 {
            bottom: 0;
            left: 215px;
        }
        &.col--8 {
            width: 175px;
            height: 175px;
            bottom: 20px;
            left: 0;   
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: 300ms;
        }
        .advantages__title {
            display: flex;
            min-height: 0px;
            flex-direction: column;
            justify-content: center;
            background-color: rgba(111, 109, 107, 0.7);
            font-size: 17px;
            text-align: center;
            color: #fefefe;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            padding: 7px 0;
            transform: translateY(-50%);
            transition: 400ms;
            line-height: 1.2;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
            .advantages__title {
                opacity: 0;
            }
        }
    }
    .advantages__block--logo {
        display: block;
        width: 196px;
        height: 235px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }
    @media screen and (max-width: 1199px) {
        height: 450px;
        margin: 30px 0;
        .advantages__block--col {
            width: 190px;
            height: 190px;
            &.col--1 {
                left: 30px;
                top: 120px;
            }
            &.col--2 {
                left: 175px;
            }
            &.col--3 {
                right: 20px;
            }
            &.col--4 {
                right: 155px;
            }
            &.col--6 {
                right: 185px;
            }
            &.col--7 {
                left: 190px;
            }
            &.col--8 {
                bottom: 0px;
            }
            &.large {
                width: 220px;
                height: 220px;
            }
            &.small {
                width: 140px;
                height: 140px;
            }
            .advantages__title {
                padding: 10px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        height: 380px;
        .advantages__block--col {
            width: 150px;
            height: 150px;
            &.large {
                width: 175px;
                height: 175px;
            }
            &.small {
                width: 120px;
                height: 120px;
            }
            &.col--1 {
                top: 105px;
            }
            &.col--2 {
                left: 120px;
            }
            &.col--3 {
                right: 0;
            }
            &.col--4 {
                top: 27px;
                right: 110px;
            }
            &.col--6 {
                right: 140px;
            }
            &.col--7 {
                left: 140px;
            }
            .advantages__title {
                font-size: 14px;
            }
        }
        .advantages__block--logo {
            width: 150px;
            height: 200px;
        }
    }
    @media screen and (max-width: 768px){
        height: auto;
        text-align: center;
        .advantages__block--logo {
            position: relative;
            top: 0;
            transform: translate(0,0);
        }
        .advantages__block--col {
            @include inline-block;
            width: 250px!important;
            height: 250px!important;
            position: relative;
            width: 200px;
            height: 200px;
            left: 0!important;
            right: 0!important;
            top: 0!important;
            bottom: 0!important;
            margin: 15px 15px;
            .advantages__title {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        margin: 20px 0;
        .advantages__block--col {
            width: 200px!important;
            height: 200px!important;
        }
    }
    @media screen and (max-width: 500px) {
        .advantages__block--col {
            width: 150px!important;
            height: 150px!important;
            margin: 5px;
            .advantages__title {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        margin: 0 -10px;
        .advantages__block--col {
            width: 150px!important;
            height: 150px!important;
            margin: 10px 5px;
        }
    }
    @media screen and (max-width: 380px) {
        .advantages__block--col {
            width: 135px!important;
            height: 135px!important;
        }
    }
}
/* main advantages */

/* main video */
.section--video {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 1170px;
    padding: 0 15px;
    margin: 10px auto 0px;
    .video__block {
        width: calc(50% - 15px);
        padding: 15px;
        border: 1px solid #c6c6c6;
        .video__block--img {
            height: 295px;
            margin-bottom: 15px;
            border: 1px solid #c6c6c6;
            img, iframe {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .video__block--title {
            display: flex;
            align-items: center;
            min-height: 20px;
            line-height: 1.2;
        }
    }
    @media  screen and (max-width: 1199px) {
        margin: 35px 0;
        .video__block .video__block--img {
            height: 250px;
        }
    }
    @media screen and (max-width: 991px) {
        margin: 25px 0;
        .video__block .video__block--img {
            height: 220px;
        }
    }
    @media screen and (max-width: 768px) {
        .video__block {
            width: calc(50% - 10px);
        }
    }
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        margin-bottom: 0;
        .video__block {
            width: 100%;
            margin-bottom: 25px;
            .video__block--img {
                height: auto;
                img {
                    height: auto;
                }
            }
        }
    }
}
/* main video */

.btn--up {
    display: block;
    font-family: $bold;
    font-size: 18px;
    text-transform: uppercase;
    color: #ff181f!important;
    position: fixed;
    right: 50px;
    padding-right: 25px;
    bottom: 50px;
    z-index: 1;
    opacity: 0;
    transition: 300ms;
    &:after {
        content: '\f077';
        font-family: 'fontAwesome';
        font-size: 18px;
        color: #ff181f;
        position: absolute;
        right: 0;
        top: 3px;
        transition: 300ms;
    }
    &:hover {
        color: #261b17!important;
        &:after {
            color: #261b17;
        }
    }
    @media screen and (max-width: 991px) {
        display: none;
    }
}

/* main content */