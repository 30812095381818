@charset "UTF-8";
/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
@font-face {
  font-family: 'FuturaPTBold';
  src: url("../fonts/FuturaPTBold.eot");
  src: url("../fonts/FuturaPTBold.eot") format("embedded-opentype"), url("../fonts/FuturaPTBold.woff2") format("woff2"), url("../fonts/FuturaPTBold.woff") format("woff"), url("../fonts/FuturaPTBold.ttf") format("truetype"), url("../fonts/FuturaPTBold.svg#FuturaPTBold") format("svg"); }

@font-face {
  font-family: 'FuturaPTBook';
  src: url("../fonts/FuturaPTBook.eot");
  src: url("../fonts/FuturaPTBook.eot") format("embedded-opentype"), url("../fonts/FuturaPTBook.woff2") format("woff2"), url("../fonts/FuturaPTBook.woff") format("woff"), url("../fonts/FuturaPTBook.ttf") format("truetype"), url("../fonts/FuturaPTBook.svg#FuturaPTBook") format("svg"); }

@font-face {
  font-family: 'FuturaPTMedium';
  src: url("../fonts/FuturaPTMedium.eot");
  src: url("../fonts/FuturaPTMedium.eot") format("embedded-opentype"), url("../fonts/FuturaPTMedium.woff2") format("woff2"), url("../fonts/FuturaPTMedium.woff") format("woff"), url("../fonts/FuturaPTMedium.ttf") format("truetype"), url("../fonts/FuturaPTMedium.svg#FuturaPTMedium") format("svg"); }

@font-face {
  font-family: 'GOTHIC';
  src: url("../fonts/GOTHIC.eot");
  src: url("../fonts/GOTHIC.eot") format("embedded-opentype"), url("../fonts/GOTHIC.woff2") format("woff2"), url("../fonts/GOTHIC.woff") format("woff"), url("../fonts/GOTHIC.ttf") format("truetype"), url("../fonts/GOTHIC.svg#GOTHIC") format("svg"); }

@font-face {
  font-family: 'GOTHICB';
  src: url("../fonts/GOTHICB.eot");
  src: url("../fonts/GOTHICB.eot") format("embedded-opentype"), url("../fonts/GOTHICB.woff2") format("woff2"), url("../fonts/GOTHICB.woff") format("woff"), url("../fonts/GOTHICB.ttf") format("truetype"), url("../fonts/GOTHICB.svg#GOTHICB") format("svg"); }

@font-face {
  font-family: 'ROMUL';
  src: url("../fonts/ROMUL.eot");
  src: url("../fonts/ROMUL.eot") format("embedded-opentype"), url("../fonts/ROMUL.woff2") format("woff2"), url("../fonts/ROMUL.woff") format("woff"), url("../fonts/ROMUL.ttf") format("truetype"), url("../fonts/ROMUL.svg#ROMUL") format("svg"); }

@font-face {
  font-family: 'Conv_CALIBRI_0';
  src: url("../fonts/CALIBRI_0.eot");
  src: local("☺"), url("../fonts/CALIBRI_0.woff") format("woff"), url("../fonts/CALIBRI_0.ttf") format("truetype"), url("../fonts/CALIBRI_0.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_CALIBRIB_0';
  src: url("../fonts/CALIBRIB_0.eot");
  src: local("☺"), url("../fonts/CALIBRIB_0.woff") format("woff"), url("../fonts/CALIBRIB_0.ttf") format("truetype"), url("../fonts/CALIBRIB_0.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ar_essenceregular';
  src: url("../fonts/aressence-webfont.eot");
  src: url("../fonts/aressence-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aressence-webfont.woff2") format("woff2"), url("../fonts/aressence-webfont.woff") format("woff"), url("../fonts/aressence-webfont.ttf") format("truetype"), url("../fonts/aressence-webfont.svg#ar_essenceregular") format("svg");
  font-weight: normal;
  font-style: normal; }

*:after, *:before {
  display: block;
  line-height: 1; }

body {
  display: flex;
  min-width: 300px;
  height: 100vh;
  position: relative;
  flex-direction: column;
  font-family: "FuturaPTBook";
  color: #261b17;
  margin: 0; }
  body.load a {
    transition: 300ms; }
  @media screen and (max-width: 991px) {
    body {
      padding-top: 100px; } }
  @media screen and (max-width: 580px) {
    body {
      padding-top: 80px; } }

h1 {
  font-family: "GOTHIC";
  font-size: 42px;
  text-transform: uppercase;
  text-align: center;
  color: #261b17;
  line-height: 1;
  margin: 0 0 40px; }
  h1 a {
    font-size: 42px; }
  @media screen and (max-width: 1199px) {
    h1 {
      font-size: 32px; }
      h1 a {
        font-size: 32px; } }
  @media screen and (max-width: 991px) {
    h1 {
      font-size: 28px;
      margin-bottom: 30px; }
      h1 a {
        font-size: 28px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
      margin-bottom: 20px; }
      h1 a {
        font-size: 24px; } }

h2 {
  font-family: "GOTHIC";
  font-size: 36px;
  color: #261b17;
  text-transform: uppercase;
  margin: 0 0 40px; }
  h2 a {
    font-size: 36px; }
  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 30px; }
      h2 a {
        font-size: 30px; } }
  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
      margin-bottom: 30px; }
      h2 a {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 22px;
      margin-bottom: 20px; }
      h2 a {
        font-size: 22px; } }

h3 {
  font-family: "FuturaPTMedium";
  font-size: 24px;
  color: #261b17; }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 20px) {
    h3 {
      font-size: 20px; } }

h4 {
  font-family: "FuturaPTMedium";
  font-size: 20px;
  color: #261b17; }
  @media screen and (max-width: 991px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-family: "FuturaPTMedium";
  font-size: 18px;
  color: #261b17; }

h6 {
  font-family: "FuturaPTBold";
  font-size: 16px;
  text-transform: uppercase;
  color: #261b17; }

a {
  color: #261b17;
  text-decoration: none; }
  a:focus {
    color: #261b17;
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #ff181f; }

textarea,
button,
select,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"] {
  border-radius: 0px;
  -webkit-appearance: none; }

.main, .page {
  position: relative;
  flex: 1 0 auto;
  font-size: 18px;
  line-height: 1.2; }
  .main textarea,
  .main input[type="text"],
  .main input[type="email"],
  .main input[type="search"],
  .main input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    width: 100%;
    background-color: #fff;
    font-family: "FuturaPTBook";
    font-size: 18px;
    padding: 0;
    margin-bottom: 15px;
    border: none;
    border-bottom: 1px solid #8f8f8f;
    box-shadow: none;
    outline: none; }
    .main textarea::placeholder,
    .main input[type="text"]::placeholder,
    .main input[type="email"]::placeholder,
    .main input[type="search"]::placeholder,
    .main input[type="password"]::placeholder, .page textarea::placeholder,
    .page input[type="text"]::placeholder,
    .page input[type="email"]::placeholder,
    .page input[type="search"]::placeholder,
    .page input[type="password"]::placeholder {
      color: #7f7f7f; }
  .main input[name="tel"], .page input[name="tel"] {
    border: none; }

.main {
  padding-bottom: 50px; }
  @media screen and (max-width: 1199px) {
    .main {
      padding-bottom: 0; } }

.close {
  position: absolute;
  right: 20px;
  top: 18px;
  font-family: "Conv_CALIBRI_0";
  font-size: 16px;
  color: #8c8c8b;
  opacity: 1;
  outline: none;
  padding-right: 27px;
  transition: 300ms; }
  .close:before {
    content: '';
    width: 15px;
    height: 15px;
    background: url(../img/close.png) no-repeat center center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }

.btn {
  display: table;
  height: 40px;
  width: auto !important;
  background-color: #727271 !important;
  font-family: "FuturaPTBook" !important;
  font-size: 18px;
  color: #fff !important;
  cursor: pointer;
  border-radius: 0px;
  border: none;
  padding: 9px 15px 7px !important;
  line-height: 1;
  transition: 300ms; }
  .btn:focus, .btn:active {
    background-color: #727271 !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #292929 !important;
    color: #fff !important; }

input[type="submit"].btn {
  padding: 8px 15px 9px !important; }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    position: relative;
    font-size: 16px;
    padding-left: 25px;
    margin-bottom: 5px; }
    .check--list li:before {
      content: '\f00c';
      position: absolute;
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #000;
      left: 0;
      top: 0px; }

.col--location,
.col--phone,
.col--email {
  position: relative;
  padding-left: 25px;
  font-size: 16px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 20px;
    color: #304fbf;
    position: absolute;
    top: -2px;
    left: 0; }

.col--location:after {
  content: '\f041'; }

.col--phone:after {
  content: '\f095'; }

.col--email:after {
  content: '\f0e0';
  font-size: 16px;
  top: 2px; }

.green {
  color: #00993d; }

.red {
  color: #ff181f; }

.check {
  width: 20px;
  height: 20px;
  background: url(../img/checked.svg) no-repeat center center;
  background-size: contain; }

.help {
  position: relative;
  display: inline-block;
  vertical-align: top; }
  .help:after {
    content: '?';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    font-family: "Conv_CALIBRI_0";
    font-size: 13px;
    border-radius: 100%;
    background-color: #727271;
    color: #fff;
    cursor: pointer; }
  .help .help__block {
    display: none;
    width: 265px;
    background-color: #e8e8e8;
    font-family: "Conv_CALIBRI_0";
    font-size: 14px;
    color: #292929;
    position: absolute;
    top: -13px;
    left: calc(100% + 14px);
    padding: 10px 15px;
    line-height: 1.2;
    z-index: 1; }
    .help .help__block:after {
      content: '';
      position: absolute;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 9px solid #e8e8e8;
      position: absolute;
      top: 15px;
      left: -9px; }
    .help .help__block p {
      margin: 0; }
  @media screen and (max-width: 480px) {
    .help .help__block {
      left: auto;
      right: 0; } }

.title--icon {
  position: relative; }
  .title--icon:after {
    content: '';
    width: 20px;
    height: 20px;
    background: url(../img/icon17.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    left: -32px;
    top: 50%;
    transform: translateY(-50%); }

/* header */
header {
  position: relative;
  line-height: 1.2;
  z-index: 9;
  /* header logo */
  /* header logo */
  /* header nav */
  /* header nav */ }
  header .container {
    position: relative;
    min-height: 137px; }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header .logo {
    display: inline-block;
    vertical-align: top;
    width: 320px;
    height: 100px;
    margin-top: 20px; }
    header .logo img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center; }
  header .header__text {
    display: block;
    width: 600px;
    font-family: "Conv_CALIBRIB_0";
    font-size: 16px;
    color: #000;
    text-align: right;
    position: absolute;
    right: 15px;
    top: 20px; }
  header .nav {
    display: inline-block;
    vertical-align: top;
    max-width: 780px;
    float: right;
    margin-top: 77px;
    line-height: 1; }
    header .nav > ul {
      display: flex;
      flex-wrap: wrap; }
      header .nav > ul > li {
        position: relative; }
        header .nav > ul > li:after {
          content: '';
          width: 2px;
          height: 100%;
          background: url(../img/line.jpg) no-repeat center center;
          background-size: cover;
          position: absolute;
          right: -1px;
          top: 0;
          z-index: 1; }
        header .nav > ul > li:last-of-type:after {
          display: none; }
        header .nav > ul > li > a {
          display: flex;
          align-items: center;
          height: 40px;
          max-width: 160px;
          background-color: #000;
          font-family: "Conv_CALIBRI_0";
          font-size: 16px;
          color: #fff;
          text-align: center;
          padding: 5px 15px; }
          header .nav > ul > li > a img {
            max-width: 22px;
            height: 22px;
            object-fit: contain;
            object-position: center center;
            margin-right: 4px; }
          header .nav > ul > li > a:hover {
            background-color: #f31d23; }
        header .nav > ul > li.active > a {
          background-color: #f31d23;
          color: #fff; }
        header .nav > ul > li.home > a {
          width: 42px;
          padding-left: 10px;
          padding-right: 10px; }
          header .nav > ul > li.home > a img {
            margin: 0; }
        header .nav > ul > li.like > a {
          background-color: #f31d23;
          padding-left: 10px;
          padding-right: 10px; }
          header .nav > ul > li.like > a:hover {
            background-color: #000; }
          header .nav > ul > li.like > a img {
            margin: 0; }
        header .nav > ul > li.like.active > a {
          background-color: #2b2a29; }
        header .nav > ul > li ul {
          display: none;
          background-color: #000;
          position: relative;
          padding: 5px; }
          header .nav > ul > li ul li {
            text-align: center;
            margin: 7px 0; }
            header .nav > ul > li ul li a {
              font-family: "Conv_CALIBRI_0";
              font-size: 16px;
              color: #fff; }
              header .nav > ul > li ul li a:hover {
                color: #fff; }
            header .nav > ul > li ul li.active a {
              color: #f31d23; }
  @media screen and (max-width: 1199px) {
    header .logo {
      width: 200px; } }
  @media screen and (min-width: 992px) {
    header .nav > ul > li:nth-of-type(3) > a {
      padding-left: 16px; } }
  @media screen and (max-width: 991px) {
    header {
      height: 100px;
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); }
      header .container {
        min-height: 100px; }
      header .logo {
        display: block;
        width: 180px;
        height: 60px;
        margin: 4px auto 0; }
      header .header__text {
        font-size: 14px; }
      header .nav {
        display: none;
        max-width: 100%;
        max-height: calc(100% - 100px);
        position: fixed;
        top: 100px;
        left: 0;
        right: 0;
        margin: 0;
        float: none;
        overflow: auto;
        z-index: 1;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); }
        header .nav > ul {
          display: block; }
          header .nav > ul > li {
            display: block;
            border-bottom: 1px solid #fff; }
            header .nav > ul > li:after {
              display: none; }
            header .nav > ul > li.home > a {
              width: 100%; }
            header .nav > ul > li:nth-of-type(3) > a {
              max-width: initial; }
            header .nav > ul > li > a {
              max-width: initial;
              justify-content: center; }
              header .nav > ul > li > a > br {
                display: none; }
      header .header__text {
        width: auto;
        top: 67px;
        left: 10px;
        right: 10px;
        font-size: 12px;
        text-align: center;
        line-height: 1; }
      header .nav--btn {
        width: 35px;
        height: 30px;
        position: absolute;
        left: 10px;
        top: 50%;
        padding: 0;
        margin: 0;
        outline: none;
        float: none;
        transform: translateY(-50%); }
        header .nav--btn span {
          position: absolute;
          left: 0;
          right: 0;
          width: 85%;
          height: 3px;
          margin: 0 auto;
          background-color: #e31e23;
          transition: 350ms; }
          header .nav--btn span:nth-of-type(1) {
            top: 7px; }
          header .nav--btn span:nth-of-type(2) {
            top: 14px; }
          header .nav--btn span:nth-of-type(3) {
            top: 21px; }
        header .nav--btn.open span:nth-of-type(1) {
          top: 14px;
          transform: rotate(45deg); }
        header .nav--btn.open span:nth-of-type(2) {
          opacity: 0; }
        header .nav--btn.open span:nth-of-type(3) {
          top: 14px;
          transform: rotate(-45deg); } }
  @media screen and (max-width: 580px) {
    header {
      height: 80px; }
      header .container {
        min-height: 80px; }
      header .logo {
        height: 50px; }
      header .header__text {
        width: 320px;
        font-size: 9px;
        left: 0;
        right: 0;
        top: 58px;
        margin: 0 auto; }
      header .nav {
        max-height: calc(100% - 80px);
        top: 80px; } }
  @media screen and (max-width: 420px) {
    header .nav--btn {
      top: 40%; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider--block {
  position: relative;
  padding-bottom: 25px; }
  .slider--block .info--block {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(475px, -12px); }
  @media screen and (max-width: 1199px) {
    .slider--block {
      padding-bottom: 0px; }
      .slider--block .info--block {
        right: 85px;
        transform: translate(0, -50%); } }
  @media screen and (max-width: 768px) {
    .slider--block .info--block {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      transform: translate(0, 0);
      margin: 25px auto; } }

.slider {
  opacity: 0;
  transition: 400ms;
  margin-bottom: 0px; }
  .slider.slick-initialized {
    opacity: 1; }
  .slider .slick-slide {
    height: 520px;
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .slider .slick-slide .slider__block {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      text-align: center;
      color: #fff; }
  .slider .slick-arrow {
    width: 50px;
    height: 50px;
    border-radius: 3px;
    overflow: hidden;
    opacity: 0.8;
    background-color: #414040;
    transition: 300ms;
    z-index: 1; }
    .slider .slick-arrow:before {
      display: none; }
    .slider .slick-arrow:after {
      content: '';
      background: url(../img/next.svg) no-repeat center center;
      background-size: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .slider .slick-arrow.slick-prev {
      left: 50%;
      margin-left: -570px; }
    .slider .slick-arrow.slick-next {
      right: 50%;
      margin-right: -570px; }
      .slider .slick-arrow.slick-next:after {
        transform: rotate(-180deg); }
    .slider .slick-arrow:hover {
      opacity: 1; }
  @media screen and (max-width: 1199px) {
    .slider .slick-arrow.slick-prev {
      left: 15px;
      margin: 0; }
    .slider .slick-arrow.slick-next {
      right: 15px;
      margin: 0; } }
  @media screen and (max-width: 991px) {
    .slider .slick-arrow {
      width: 40px;
      height: 40px; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide {
      height: auto; } }

.info--block {
  width: 320px;
  text-align: center;
  position: relative;
  background-color: #e8e8e8;
  border: 10px solid #fff;
  padding: 10px; }
  .info--block.gray {
    border: 10px solid #727271; }
    .info--block.gray:before {
      border: 2px solid #727271; }
  .info--block:before {
    content: '';
    position: absolute;
    left: -12px;
    right: -12px;
    top: -12px;
    bottom: -12px;
    border: 2px solid #d1d1d1; }
  .info--block:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 2px solid #d1d1d1; }
  .info--block * {
    position: relative;
    z-index: 1; }
  .info--block h5 {
    font-family: "Conv_CALIBRI_0";
    font-size: 17px;
    color: #2b2a29;
    margin: 0 0 10px 0 !important; }
  .info--block p {
    margin: 0; }
  .info--block .info--block__num {
    position: relative;
    padding: 5px 0; }
    .info--block .info--block__num:before {
      content: '';
      height: 20px;
      width: 100%;
      background-color: #292929;
      position: absolute;
      bottom: 13px;
      left: 0;
      right: 0;
      z-index: -1; }
    .info--block .info--block__num .icon {
      display: inline-block;
      vertical-align: top;
      width: 36px;
      height: 36px;
      background-color: #fff;
      border-radius: 100%;
      box-shadow: 0px 3px 5px #464646;
      padding: 8px;
      margin-right: 5px;
      margin-top: 0px; }
      .info--block .info--block__num .icon img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center; }
    .info--block .info--block__num a {
      font-family: "Conv_CALIBRIB_0";
      font-size: 30px;
      color: #2b2a29;
      z-index: 2; }
      .info--block .info--block__num a span {
        display: inline-block;
        vertical-align: top;
        color: #fff;
        margin: 0 3px 8px; }
    .info--block .info--block__num .city {
      display: table;
      position: relative;
      font-family: "FuturaPTBold";
      font-size: 15px;
      color: #fff;
      text-transform: uppercase;
      padding: 0 5px 10px;
      margin: 0 0 0 78px;
      line-height: 1; }
      .info--block .info--block__num .city:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: -50px;
        border-radius: 5px;
        background-color: #292929;
        z-index: -1; }
  @media screen and (max-width: 420px) {
    .info--block {
      width: 285px; }
      .info--block h5 {
        font-size: 15px; }
      .info--block .info--block__num {
        padding: 0; }
        .info--block .info--block__num:before {
          height: 15px;
          bottom: 7px; }
        .info--block .info--block__num .icon {
          width: 29px;
          height: 29px;
          margin-top: -2px;
          margin-right: 3px; }
        .info--block .info--block__num a {
          font-size: 24px; }
        .info--block .info--block__num .city {
          font-size: 12px;
          margin-left: 74px;
          margin-top: -5px; }
          .info--block .info--block__num .city:before {
            top: -35px;
            bottom: 1px; } }

/* main slider */
/* main catalog */
.cat {
  padding-bottom: 25px; }

.title {
  position: relative;
  overflow: hidden;
  margin: 50px 0; }
  .title:before, .title:after {
    content: '';
    width: 50%;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .title:before {
    background-color: #009846;
    left: 0; }
  .title:after {
    background-color: #e31e24;
    right: 0; }
  .title > * {
    display: table;
    position: relative;
    padding: 0 125px;
    background-color: #fff;
    text-align: center;
    z-index: 1;
    margin: 0 auto; }
    .title > *:before, .title > *:after {
      content: '';
      width: 25px;
      height: 25px;
      background: url(../img/icon20.png) no-repeat center center;
      background-size: contain;
      filter: grayscale(100%);
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .title > *:before {
      left: 55px; }
    .title > *:after {
      right: 55px; }
  @media screen and (max-width: 991px) {
    .title {
      margin: 30px 0; }
      .title > * {
        padding: 0px 75px; }
        .title > *:after {
          right: 30px; }
        .title > *:before {
          left: 30px; } }
  @media screen and (max-width: 768px) {
    .title {
      padding: 0 15px;
      margin: 20px 0; }
      .title > * {
        padding: 0 50px; }
        .title > *:after {
          width: 20px;
          height: 20px;
          right: 15px; }
        .title > *:before {
          width: 20px;
          height: 20px;
          left: 15px; } }

.cat__block {
  display: flex;
  flex-wrap: wrap;
  position: relative; }
  .cat__block .col {
    height: 200px;
    width: calc(16.66%);
    padding: 8px;
    opacity: 0.2; }
    .cat__block .col img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center bottom; }
    .cat__block .col:hover {
      opacity: 1; }
  .cat__block .cat--title {
    position: absolute;
    font-family: "GOTHIC";
    font-size: 34px;
    text-transform: uppercase;
    color: #2b2a29;
    transform: rotate(-90deg); }
    .cat__block .cat--title.first {
      left: 0;
      top: 0;
      margin: 50px 0 0 -80px; }
    .cat__block .cat--title.second {
      bottom: 0;
      right: 0;
      margin: 0 -75px 60px 0; }
  @media screen and (max-width: 1199px) {
    .cat__block .col {
      height: 150px; }
    .cat__block .cat--title {
      font-size: 26px; }
      .cat__block .cat--title.first {
        margin: 50px 0 0 -50px; }
      .cat__block .cat--title.second {
        margin: 0 -40px 30px 0; } }
  @media screen and (max-width: 991px) {
    .cat__block .col {
      height: 125px; } }
  @media screen and (max-width: 768px) {
    .cat__block .col {
      padding: 15px; }
    .cat__block .cat--title {
      width: 100%;
      position: relative;
      top: 0;
      transform: rotate(0deg); }
      .cat__block .cat--title.first, .cat__block .cat--title.second {
        margin: 15px 0; } }
  @media screen and (max-width: 580px) {
    .cat__block .col {
      height: 100px;
      padding: 5px; } }
  @media screen and (max-width: 420px) {
    .cat__block .col {
      height: 80px;
      padding: 5px; } }

/* main catalog */
/* main advantages */
.advantages__block {
  height: 540px;
  position: relative;
  margin: 60px 0; }
  .advantages__block .advantages__block--col {
    width: 230px;
    height: 230px;
    position: absolute;
    border-radius: 100%;
    border: 2px solid #b2b4b3;
    overflow: hidden; }
    .advantages__block .advantages__block--col.large {
      width: 250px;
      height: 250px; }
    .advantages__block .advantages__block--col.small {
      width: 165px;
      height: 165px; }
    .advantages__block .advantages__block--col.col--1 {
      top: 140px;
      left: 120px;
      z-index: 1; }
    .advantages__block .advantages__block--col.col--2 {
      top: 0;
      left: 240px; }
    .advantages__block .advantages__block--col.col--3 {
      top: 125px;
      right: 60px;
      z-index: 1; }
    .advantages__block .advantages__block--col.col--4 {
      top: 0;
      right: 220px; }
    .advantages__block .advantages__block--col.col--5 {
      top: 10px;
      right: 0; }
    .advantages__block .advantages__block--col.col--6 {
      bottom: 0;
      right: 205px; }
    .advantages__block .advantages__block--col.col--7 {
      bottom: 0;
      left: 215px; }
    .advantages__block .advantages__block--col.col--8 {
      width: 175px;
      height: 175px;
      bottom: 20px;
      left: 0; }
    .advantages__block .advantages__block--col img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: 300ms; }
    .advantages__block .advantages__block--col .advantages__title {
      display: flex;
      min-height: 0px;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(111, 109, 107, 0.7);
      font-size: 17px;
      text-align: center;
      color: #fefefe;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      padding: 7px 0;
      transform: translateY(-50%);
      transition: 400ms;
      line-height: 1.2; }
    .advantages__block .advantages__block--col:hover img {
      transform: scale(1.1); }
    .advantages__block .advantages__block--col:hover .advantages__title {
      opacity: 0; }
  .advantages__block .advantages__block--logo {
    display: block;
    width: 196px;
    height: 235px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%); }
    .advantages__block .advantages__block--logo img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center; }
  @media screen and (max-width: 1199px) {
    .advantages__block {
      height: 450px;
      margin: 30px 0; }
      .advantages__block .advantages__block--col {
        width: 190px;
        height: 190px; }
        .advantages__block .advantages__block--col.col--1 {
          left: 30px;
          top: 120px; }
        .advantages__block .advantages__block--col.col--2 {
          left: 175px; }
        .advantages__block .advantages__block--col.col--3 {
          right: 20px; }
        .advantages__block .advantages__block--col.col--4 {
          right: 155px; }
        .advantages__block .advantages__block--col.col--6 {
          right: 185px; }
        .advantages__block .advantages__block--col.col--7 {
          left: 190px; }
        .advantages__block .advantages__block--col.col--8 {
          bottom: 0px; }
        .advantages__block .advantages__block--col.large {
          width: 220px;
          height: 220px; }
        .advantages__block .advantages__block--col.small {
          width: 140px;
          height: 140px; }
        .advantages__block .advantages__block--col .advantages__title {
          padding: 10px; } }
  @media screen and (max-width: 991px) {
    .advantages__block {
      height: 380px; }
      .advantages__block .advantages__block--col {
        width: 150px;
        height: 150px; }
        .advantages__block .advantages__block--col.large {
          width: 175px;
          height: 175px; }
        .advantages__block .advantages__block--col.small {
          width: 120px;
          height: 120px; }
        .advantages__block .advantages__block--col.col--1 {
          top: 105px; }
        .advantages__block .advantages__block--col.col--2 {
          left: 120px; }
        .advantages__block .advantages__block--col.col--3 {
          right: 0; }
        .advantages__block .advantages__block--col.col--4 {
          top: 27px;
          right: 110px; }
        .advantages__block .advantages__block--col.col--6 {
          right: 140px; }
        .advantages__block .advantages__block--col.col--7 {
          left: 140px; }
        .advantages__block .advantages__block--col .advantages__title {
          font-size: 14px; }
      .advantages__block .advantages__block--logo {
        width: 150px;
        height: 200px; } }
  @media screen and (max-width: 768px) {
    .advantages__block {
      height: auto;
      text-align: center; }
      .advantages__block .advantages__block--logo {
        position: relative;
        top: 0;
        transform: translate(0, 0); }
      .advantages__block .advantages__block--col {
        display: inline-block;
        vertical-align: top;
        width: 250px !important;
        height: 250px !important;
        position: relative;
        width: 200px;
        height: 200px;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;
        margin: 15px 15px; }
        .advantages__block .advantages__block--col .advantages__title {
          font-size: 16px; } }
  @media screen and (max-width: 600px) {
    .advantages__block {
      margin: 20px 0; }
      .advantages__block .advantages__block--col {
        width: 200px !important;
        height: 200px !important; } }
  @media screen and (max-width: 500px) {
    .advantages__block .advantages__block--col {
      width: 150px !important;
      height: 150px !important;
      margin: 5px; }
      .advantages__block .advantages__block--col .advantages__title {
        font-size: 14px; } }
  @media screen and (max-width: 500px) {
    .advantages__block {
      margin: 0 -10px; }
      .advantages__block .advantages__block--col {
        width: 150px !important;
        height: 150px !important;
        margin: 10px 5px; } }
  @media screen and (max-width: 380px) {
    .advantages__block .advantages__block--col {
      width: 135px !important;
      height: 135px !important; } }

/* main advantages */
/* main video */
.section--video {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  max-width: 1170px;
  padding: 0 15px;
  margin: 10px auto 0px; }
  .section--video .video__block {
    width: calc(50% - 15px);
    padding: 15px;
    border: 1px solid #c6c6c6; }
    .section--video .video__block .video__block--img {
      height: 295px;
      margin-bottom: 15px;
      border: 1px solid #c6c6c6; }
      .section--video .video__block .video__block--img img, .section--video .video__block .video__block--img iframe {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
    .section--video .video__block .video__block--title {
      display: flex;
      align-items: center;
      min-height: 20px;
      line-height: 1.2; }
  @media screen and (max-width: 1199px) {
    .section--video {
      margin: 35px 0; }
      .section--video .video__block .video__block--img {
        height: 250px; } }
  @media screen and (max-width: 991px) {
    .section--video {
      margin: 25px 0; }
      .section--video .video__block .video__block--img {
        height: 220px; } }
  @media screen and (max-width: 768px) {
    .section--video .video__block {
      width: calc(50% - 10px); } }
  @media screen and (max-width: 600px) {
    .section--video {
      flex-wrap: wrap;
      margin-bottom: 0; }
      .section--video .video__block {
        width: 100%;
        margin-bottom: 25px; }
        .section--video .video__block .video__block--img {
          height: auto; }
          .section--video .video__block .video__block--img img {
            height: auto; } }

/* main video */
.btn--up {
  display: block;
  font-family: "FuturaPTBold";
  font-size: 18px;
  text-transform: uppercase;
  color: #ff181f !important;
  position: fixed;
  right: 50px;
  padding-right: 25px;
  bottom: 50px;
  z-index: 1;
  opacity: 0;
  transition: 300ms; }
  .btn--up:after {
    content: '\f077';
    font-family: 'fontAwesome';
    font-size: 18px;
    color: #ff181f;
    position: absolute;
    right: 0;
    top: 3px;
    transition: 300ms; }
  .btn--up:hover {
    color: #261b17 !important; }
    .btn--up:hover:after {
      color: #261b17; }
  @media screen and (max-width: 991px) {
    .btn--up {
      display: none; } }

/* main content */
/* page content */
.page {
  padding: 20px 0 50px 0; }
  .page .news1 .date, .page .news .date {
    color: #ff181f; }
  .page .page_nav .active {
    color: #ff181f; }
  .page #ask-form {
    max-width: 350px; }
  .page .write {
    color: #ff181f; }
  .page .back_link {
    margin: 25px 0; }
  .page.page1 {
    padding-bottom: 20px; }
  .page.page-advantages .section--video {
    margin-top: 40px; }
  @media screen and (max-width: 1199px) {
    .page {
      padding-bottom: 30px; }
      .page.page-advantages .section--video {
        margin-top: 20px; }
      .page.page1 {
        padding-bottom: 0px; } }
  @media screen and (max-width: 1170px) {
    .page {
      font-size: 16px; }
      .page #photos-list td img {
        width: 100%;
        height: auto; } }
  @media screen and (max-width: 991px) {
    .page {
      padding-bottom: 20px; } }
  @media screen and (max-width: 500px) {
    .page.page-advantages .section--video {
      margin-top: 10px; } }
  @media screen and (max-width: 480px) {
    .page p img, .page p strong img {
      width: 100% !important;
      height: auto !important;
      display: block !important;
      float: none !important;
      margin: 0 0 10px 0 !important; }
    .page #photos-list td {
      width: 50%;
      display: inline-block;
      vertical-align: top; }
    .page.page-advantages .advantages--text {
      padding: 20px 0 15px; } }

/* breadcrumbs */
.breadcrumbs {
  font-size: 16px; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      color: #3361eb;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        text-decoration: none; }
      .breadcrumbs ul li:after {
        content: '/';
        margin: 0px 2px 0px 7px;
        color: #282727;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }

/* breadcrumbs */
/* page catalog */
.cat__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .cat__list .cat__col {
    width: calc(33.33% - 30px);
    height: 240px;
    position: relative;
    margin: 0 15px 30px; }
    .cat__list .cat__col:nth-last-child(-n+3) {
      margin-bottom: 0; }
    .cat__list .cat__col img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .cat__list .cat__col .cat__title {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(43, 42, 41, 0.7);
      font-family: "FuturaPTBook";
      font-size: 36px;
      text-align: center;
      color: #fff;
      line-height: 1.2;
      opacity: 0;
      transition: 300ms;
      transform: scale(0.5); }
    .cat__list .cat__col:hover .cat__title {
      opacity: 1;
      transform: scale(1); }
  @media screen and (max-width: 1199px) {
    .cat__list .cat__col {
      height: 190px; } }
  @media screen and (max-width: 991px) {
    .cat__list {
      margin: 0 -10px; }
      .cat__list .cat__col {
        width: calc(33.33% - 20px);
        margin: 0 10px 20px; } }
  @media screen and (max-width: 768px) {
    .cat__list .cat__col .cat__title {
      font-size: 28px;
      line-height: 1;
      opacity: 1;
      transform: scale(1); } }
  @media screen and (max-width: 600px) {
    .cat__list .cat__col {
      width: calc(50% - 20px); }
      .cat__list .cat__col .cat__title {
        font-size: 24px; } }
  @media screen and (max-width: 540px) {
    .cat__list .cat__col {
      height: 150px; }
      .cat__list .cat__col .cat__title {
        font-size: 22px; } }
  @media screen and (max-width: 420px) {
    .cat__list {
      margin: 0; }
      .cat__list .cat__col {
        width: 100%;
        height: 220px;
        margin: 0 0 20px; }
        .cat__list .cat__col:nth-last-child(-n+3) {
          margin-bottom: 20px; }
        .cat__list .cat__col:last-of-type {
          margin-bottom: 0; } }
  @media screen and (max-width: 360px) {
    .cat__list .cat__col {
      height: 200px; } }

.cat--inside {
  display: flex;
  flex-wrap: wrap; }
  .cat--inside.cat__unit .sidebar {
    padding-bottom: 40px; }
  .cat--inside > .left {
    display: flex;
    width: calc(100% - 275px);
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    padding: 25px 35px 0 20px; }
    .cat--inside > .left h5 {
      width: 100%;
      margin: 30px 0; }
  .cat--inside .cat--inside__col {
    width: calc(33.33% - 40px);
    text-align: center;
    line-height: 1.2;
    position: relative;
    margin: 0 20px 20px; }
    .cat--inside .cat--inside__col:nth-last-child(-n+3) {
      margin-bottom: 0; }
    .cat--inside .cat--inside__col .cat--inside__col--img {
      height: 240px;
      width: 240px;
      margin: 0 auto; }
      .cat--inside .cat--inside__col .cat--inside__col--img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center; }
    .cat--inside .cat--inside__col p {
      margin: 0px 0px 5px 0; }
    .cat--inside .cat--inside__col .price {
      display: table;
      font-family: "Conv_CALIBRIB_0";
      font-weight: bold;
      font-size: 16px;
      color: #2b2a29;
      margin: 0 auto; }
    .cat--inside .cat--inside__col .old--price {
      text-decoration: line-through; }
  .cat--inside .cat--inside__block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 80px; }
    .cat--inside .cat--inside__block form {
      width: 470px;
      margin-right: 18px; }
      .cat--inside .cat--inside__block form input[type="text"],
      .cat--inside .cat--inside__block form input[type="emal"],
      .cat--inside .cat--inside__block form textarea {
        margin: 0; }
      .cat--inside .cat--inside__block form .g-recaptcha {
        margin: 15px 0; }
    .cat--inside .cat--inside__block table {
      width: 100%; }
      .cat--inside .cat--inside__block table tr td {
        padding: 7px 15px;
        border: 1px dashed #c1c2c2;
        vertical-align: top; }
        .cat--inside .cat--inside__block table tr td:nth-of-type(1) {
          font-family: "FuturaPTBook";
          font-size: 18px;
          white-space: nowrap;
          padding-right: 25px; }
        .cat--inside .cat--inside__block table tr td h5 {
          font-family: "FuturaPTBook";
          color: #949494;
          margin: 0 0 18px; }
        .cat--inside .cat--inside__block table tr td p {
          color: #949494;
          line-height: 1.3; }
  .cat--inside.cat--inside__full {
    margin-bottom: 35px; }
    .cat--inside.cat--inside__full .cat--inside__col {
      width: calc(25% - 40px); }
  @media screen and (max-width: 1199px) {
    .cat--inside .cat--inside__col .cat--inside__col--img {
      width: 100%; }
    .cat--inside .cat--inside__block {
      width: 100%;
      flex-wrap: wrap;
      padding-right: 0; }
      .cat--inside .cat--inside__block form {
        width: 100%;
        margin-right: 0; }
        .cat--inside .cat--inside__block form table {
          width: 100%; }
      .cat--inside .cat--inside__block .info--block {
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto; } }
  @media screen and (max-width: 991px) {
    .cat--inside > .left {
      width: 100%;
      padding: 0; }
    .cat--inside.sidebar-reverse .sidebar {
      order: 1;
      margin: 0 0 15px; }
    .cat--inside.sidebar-reverse > .left {
      order: 2; }
    .cat--inside .cat--inside__col .cat--inside__col--img {
      height: 200px; }
    .cat--inside .cat--inside__block {
      margin-top: 35px; }
      .cat--inside .cat--inside__block table tr td:nth-of-type(1) {
        font-size: 16px; }
      .cat--inside .cat--inside__block table tr td h5 {
        font-size: 16px;
        margin-bottom: 10px; } }
  @media screen and (max-width: 768px) {
    .cat--inside.cat--inside__full {
      margin-bottom: 10px; }
      .cat--inside.cat--inside__full .cat--inside__col {
        width: calc(33.33% - 30px);
        margin: 0 15px 30px; }
        .cat--inside.cat--inside__full .cat--inside__col:nth-last-child(-n+3) {
          margin-bottom: 30px; } }
  @media screen and (max-width: 600px) {
    .cat--inside .cat--inside__col {
      width: calc(50% - 30px);
      margin: 0 15px 15px; }
      .cat--inside .cat--inside__col:nth-last-child(-n+3) {
        margin-bottom: 15px; }
    .cat--inside.cat--inside__full .cat--inside__col {
      width: calc(50% - 30px);
      margin: 0 15px 15px; } }
  @media screen and (max-width: 420px) {
    .cat--inside .cat--inside__col {
      width: calc(50% - 15px);
      margin: 0 7.5px 15px; }
      .cat--inside .cat--inside__col .cat--inside__col--img {
        height: 175px; }
    .cat--inside.cat--inside__full .cat--inside__col {
      width: calc(50% - 15px);
      margin: 0 7.5px 15px; } }
  @media screen and (max-width: 380px) {
    .cat--inside .cat--inside__block table tr td {
      padding: 5px 10px; } }
  @media screen and (max-width: 340px) {
    .cat--inside .cat--inside__block form .g-recaptcha {
      transform: scale(0.9);
      transform-origin: 0 50%; } }

@media screen and (max-width: 768px) {
  .back--btn {
    margin: 0 auto; } }

.star {
  font-size: 18px;
  color: #ff181f; }

.filter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 0 0 35px 0; }
  .filter ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .filter ul li {
      display: inline-block;
      vertical-align: top;
      margin: 0 25px 0 0; }
      .filter ul li a {
        display: block;
        font-size: 18px;
        position: relative;
        padding-left: 12px; }
        .filter ul li a:after {
          content: '';
          width: 8px;
          height: 17px;
          background: url(../img/arrow--up.png) no-repeat center center;
          background-color: contain;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%); }
      .filter ul li.active a {
        color: #ff181f; }
      .filter ul li.filter__down a:after {
        transform: translateY(-50%) rotate(-180deg); }
  @media screen and (max-width: 420px) {
    .filter ul li {
      margin-right: 10px; }
      .filter ul li a {
        font-size: 16px; } }
  @media screen and (max-width: 340px) {
    .filter ul li a {
      font-size: 14px; } }

.sidebar {
  width: 275px;
  padding-left: 30px;
  border-left: 1px dashed #b2b3b3; }
  .sidebar h5 {
    display: block;
    width: auto;
    font-family: "FuturaPTMedium";
    font-size: 19px;
    text-align: center;
    padding-top: 32px;
    margin: 0 0px 15px 0;
    position: relative; }
    .sidebar h5:after {
      content: '';
      width: 20px;
      height: 20px;
      background: url(../img/icon17.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: 0 auto;
      transform: translateY(0); }
  .sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 30px; }
    .sidebar ul li {
      position: relative;
      padding-left: 35px;
      margin: 5px 0; }
      .sidebar ul li .icon {
        width: 22px;
        height: 22px;
        position: absolute;
        top: -4px;
        left: 0; }
        .sidebar ul li .icon.icon1 {
          width: 22px; }
        .sidebar ul li .icon img {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center left;
          margin: 0 !important; }
      .sidebar ul li a {
        display: table;
        font-size: 16px;
        text-transform: uppercase; }
      .sidebar ul li.active a {
        color: #ff181f; }
    .sidebar ul.sidebar__down li .icon {
      max-width: 15px; }
  @media screen and (max-width: 991px) {
    .sidebar {
      width: 100%;
      padding: 25px 0 0 0;
      border: none;
      border-top: 1px dashed #b2b3b3;
      text-align: center;
      margin-top: 15px; }
      .sidebar h5 {
        margin-right: 0; }
      .sidebar ul {
        max-width: 300px;
        margin: 0 auto 20px; }
      .sidebar .btn--like {
        margin: 0 auto; } }

.shares {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #f31d23;
  border-radius: 100%;
  font-family: "ar_essenceregular";
  font-size: 20px;
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); }

.stiker {
  position: absolute;
  right: -20px;
  top: 0px; }
  .stiker img {
    margin-bottom: 5px; }
  @media screen and (max-width: 768px) {
    .stiker {
      right: 0; } }

.btn--like {
  display: table;
  background-color: #f31d23;
  position: relative;
  font-family: "FuturaPTMedium";
  font-size: 18px;
  text-transform: uppercase;
  color: #fefefe;
  padding: 10px 15px 10px 45px;
  border-radius: 3px;
  margin: 0;
  line-height: 1; }
  .btn--like:after {
    content: '';
    width: 19px;
    height: 17px;
    background: url(../img/he-2.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%); }
  .btn--like:focus {
    color: #fff; }
  .btn--like:hover {
    color: #fff;
    opacity: 0.7; }

.like--title {
  display: block;
  width: 100%;
  font-family: "FuturaPTMedium";
  font-size: 24px;
  color: #f31d23;
  text-transform: uppercase;
  position: relative;
  padding: 0 0 0 30px;
  margin-bottom: 25px; }
  .like--title:after {
    content: '';
    width: 25px;
    height: 20px;
    background: url(../img/he.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  @media screen and (max-width: 991px) {
    .like--title {
      font-size: 20px; } }

.cat--unit {
  display: flex;
  width: 100%;
  flex-wrap: wrap; }
  .cat--unit .cat--unit__left {
    display: flex;
    width: calc(100% - 135px);
    justify-content: center; }
  .cat--unit .cat--unit__right {
    width: 135px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end; }
    .cat--unit .cat--unit__right .cat--unit__right--block {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .cat--unit .cat--unit__right .cat--unit__right--block a {
        overflow: hidden;
        max-width: 100px;
        max-height: 100px;
        margin-bottom: 18px; }
        .cat--unit .cat--unit__right .cat--unit__right--block a img {
          height: 100px;
          width: 100px; }
      .cat--unit .cat--unit__right .cat--unit__right--block img {
        display: block;
        width: 100px;
        height: 100px;
        object-fit: contain;
        object-position: center center; }
  .cat--unit .cat--unit__down {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 0 0px; }
    .cat--unit .cat--unit__down h5 {
      margin: 0 0 5px; }
    .cat--unit .cat--unit__down ul {
      list-style: none;
      margin: 0;
      padding: 0; }
    .cat--unit .cat--unit__down .help {
      margin: 4px 0 0 8px; }
  .cat--unit .cat--unit--left {
    max-width: 50%; }
  .cat--unit .cat--unit--right {
    max-width: 323px;
    margin-right: 0px; }
  .cat--unit .delivery__table {
    margin: 10px 0 0px; }
  .cat--unit .info--block {
    margin-bottom: 27px; }
  .cat--unit .cat--unit__check,
  .cat--unit .cat--unit__like {
    padding-left: 32px;
    position: relative; }
    .cat--unit .cat--unit__check .icon,
    .cat--unit .cat--unit__like .icon {
      width: 25px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
      .cat--unit .cat--unit__check .icon img,
      .cat--unit .cat--unit__like .icon img {
        display: block;
        max-width: 100%;
        margin: 0 !important; }
  .cat--unit .cat--unit__check {
    font-family: "Conv_CALIBRI_0";
    line-height: 1.2;
    margin-bottom: 10px; }
  .cat--unit .cat--unit__like {
    font-size: 18px;
    color: #ff181f;
    text-transform: uppercase; }
    .cat--unit .cat--unit__like .icon {
      width: 20px;
      height: 20px;
      transition: 300ms; }
      .cat--unit .cat--unit__like .icon img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center; }
      .cat--unit .cat--unit__like .icon.hd {
        opacity: 0; }
    .cat--unit .cat--unit__like.active .icon.vs {
      opacity: 0; }
    .cat--unit .cat--unit__like.active .icon.hd {
      opacity: 1; }
  @media screen and (max-width: 991px) {
    .cat--unit .cat--unit__down .help {
      margin-top: 2px; } }
  @media screen and (min-width: 769px) {
    .cat--unit .cat--unit__like:hover .icon.vs {
      opacity: 0; }
    .cat--unit .cat--unit__like:hover .icon.hd {
      opacity: 1; } }
  @media screen and (max-width: 768px) {
    .cat--unit .cat--unit__down .cat--unit--left {
      width: 100%;
      max-width: 100%; }
    .cat--unit .cat--unit--right {
      margin: 15px auto; }
    .cat--unit .cat--unit__left {
      width: calc(100% - 100px); }
    .cat--unit .cat--unit__right {
      width: 100px; }
      .cat--unit .cat--unit__right .cat--unit__right--block a {
        display: block;
        width: 75px;
        height: 75px; }
      .cat--unit .cat--unit__right .cat--unit__right--block img {
        width: 100px;
        height: 100px; } }
  @media screen and (max-width: 480px) {
    .cat--unit .cat--unit__left {
      width: 100%;
      padding-right: 0px;
      padding-left: 74px; }
    .cat--unit .cat--unit__right {
      display: none;
      width: 50px; }
      .cat--unit .cat--unit__right .cat--unit__right--block a {
        width: 50px;
        height: 50px; }
        .cat--unit .cat--unit__right .cat--unit__right--block a img {
          width: 50px;
          height: 50px; }
      .cat--unit .cat--unit__right .cat--unit__right--block img {
        width: 50px;
        height: 50px; }
    .cat--unit .cat--unit__down .cat--unit--left .help--block {
      position: relative; }
    .cat--unit .cat--unit__down .cat--unit--left .help {
      position: initial; }
    .cat--unit .cat--unit__down .cat--unit--left ul li {
      position: relative; }
      .cat--unit .cat--unit__down .cat--unit--left ul li .help {
        position: initial; }
        .cat--unit .cat--unit__down .cat--unit--left ul li .help .help__block {
          top: 100%;
          left: 0; }
          .cat--unit .cat--unit__down .cat--unit--left ul li .help .help__block:after {
            display: none; } }
  @media screen and (max-width: 360px) {
    .cat--unit .cat--unit__left {
      padding-left: 68px; } }
  @media screen and (max-width: 340px) {
    .cat--unit .cat--unit__left {
      padding-left: 66px;
      justify-content: flex-end; } }

.cat--unit__for {
  width: calc(100% - 275px);
  transition: 300ms;
  margin-left: 85px;
  opacity: 0; }
  .cat--unit__for.slick-initialized {
    opacity: 1; }
  .cat--unit__for .slick-slide {
    height: 400px;
    outline: none; }
    .cat--unit__for .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center; }
  @media screen and (max-width: 1200px) {
    .cat--unit__for {
      width: calc(100% - 170px);
      margin-left: 50px; } }
  @media screen and (max-width: 768px) {
    .cat--unit__for .slick-slide {
      height: 350px; } }
  @media screen and (max-width: 480px) {
    .cat--unit__for {
      width: 240px;
      padding-left: 0px;
      margin-left: 0; }
      .cat--unit__for .slick-slide {
        height: 240px; }
        .cat--unit__for .slick-slide img {
          object-fit: cover; } }
  @media screen and (max-width: 360px) {
    .cat--unit__for {
      width: 220px; }
      .cat--unit__for .slick-slide {
        height: 220px; } }
  @media screen and (max-width: 340px) {
    .cat--unit__for {
      width: 210px; }
      .cat--unit__for .slick-slide {
        height: 210px; } }

#glass {
  box-shadow: inset 0px 0px 25px rgba(0, 0, 0, 0.5); }
  @media screen and (max-width: 768px) {
    #glass {
      width: 100px !important;
      height: 100px !important; } }
  @media screen and (max-width: 480px) {
    #glass {
      width: 75px !important;
      height: 75px !important; } }

.cat--unit__nav {
  width: 120px;
  height: 400px;
  transition: 300ms;
  opacity: 0; }
  .cat--unit__nav.slick-initialized {
    opacity: 1; }
  .cat--unit__nav .slick-slide {
    height: 120px;
    outline: none;
    margin: 0px 0px 20px;
    opacity: 0.6;
    transition: 300ms;
    border: none;
    cursor: pointer; }
    .cat--unit__nav .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .cat--unit__nav .slick-slide.slick-current, .cat--unit__nav .slick-slide:hover {
      opacity: 1; }
  @media screen and (max-width: 768px) {
    .cat--unit__nav {
      width: 100px;
      height: 350px; }
      .cat--unit__nav .slick-slide {
        height: 100px; } }
  @media screen and (max-width: 480px) {
    .cat--unit__nav {
      width: 74px;
      height: 240px;
      position: absolute;
      top: 20px;
      left: 15px; }
      .cat--unit__nav .slick-slide {
        height: 74px;
        margin: 0 0 9px; } }
  @media screen and (max-width: 360px) {
    .cat--unit__nav {
      width: 68px;
      height: 220px; }
      .cat--unit__nav .slick-slide {
        height: 68px;
        margin-bottom: 8px; } }
  @media screen and (max-width: 340px) {
    .cat--unit__nav {
      width: 66px; }
      .cat--unit__nav .slick-slide {
        height: 66px;
        margin-bottom: 6px; } }

.delivery__table {
  width: 100%; }
  .delivery__table table {
    width: 100%;
    line-height: 1; }
    .delivery__table table tr th {
      background-color: #c5c6c6;
      border: 1px solid #c5c6c6;
      padding: 8px 14px;
      font-weight: normal; }
    .delivery__table table tr td {
      padding: 8px 14px;
      border: 1px dashed #bfc0c0; }
      .delivery__table table tr td .check {
        margin: 0 auto; }
  @media screen and (max-width: 380px) {
    .delivery__table table tr th, .delivery__table table tr td {
      padding: 5px 10px; } }

/* page catalog */
/* page delivery */
.delivery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }
  .delivery .left {
    width: 480px;
    margin-bottom: 25px; }
  .delivery .right {
    width: calc(100% - 480px) !important; }
  .delivery .delivery__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-left: 35px;
    margin-bottom: 35px; }
  .delivery .delivery__left {
    width: calc(100% - 495px);
    margin: 15px 0; }
  .delivery .delivery__table {
    width: 460px;
    margin: 15px 0 15px; }
    .delivery .delivery__table tr th:last-of-type {
      width: 125px;
      text-align: center; }
    .delivery .delivery__table tr td:last-of-type {
      text-align: center; }
  .delivery img {
    display: block;
    max-width: 100%; }
  .delivery h3 {
    text-transform: uppercase;
    margin: 0 0 10px; }
  @media screen and (max-width: 991px) {
    .delivery .left, .delivery .right, .delivery .delivery__left, .delivery .delivery__table {
      width: 100% !important; }
    .delivery .delivery__block {
      padding-left: 0; }
      .delivery .delivery__block .title--icon {
        padding-left: 25px; }
        .delivery .delivery__block .title--icon:after {
          left: 0; }
    .delivery .delivery__table {
      margin-top: 0; } }
  @media screen and (max-width: 380px) {
    .delivery .delivery__table table tr td:nth-of-type(1) {
      min-width: 140px; } }

.flag {
  text-align: center;
  padding: 0 0 35px; }
  .flag img {
    max-width: 100%;
    height: 15px; }

/* page delivery */
/* page warranty */
.banner {
  margin-bottom: 40px;
  border-bottom: 1px dashed #d9d9d9;
  position: relative;
  margin-top: 20px; }
  .banner.banner--item {
    border: none; }
    .banner.banner--item:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 0; }
  .banner img {
    display: block;
    width: 100%; }
  .banner h5 {
    display: flex;
    align-items: center;
    min-height: 100px;
    min-width: 460px;
    max-width: 460px;
    background-color: rgba(255, 255, 255, 0.3);
    font-family: "FuturaPTBook";
    font-size: 47px;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    bottom: 80px;
    right: 0;
    padding: 10px 20px 10px 40px;
    z-index: 1; }
  @media screen and (max-width: 768px) {
    .banner {
      margin-bottom: 25px; }
      .banner h5 {
        font-size: 28px;
        bottom: 50px; } }
  @media screen and (max-width: 580px) {
    .banner h5 {
      min-width: 0px;
      min-height: 0px;
      font-size: 24px;
      padding-left: 20px;
      margin: 0;
      bottom: 25px; } }
  @media screen and (max-width: 380px) {
    .banner h5 {
      font-size: 22px; } }

.page__logo {
  display: block;
  width: 100px;
  margin: 15px auto 25px; }
  .page__logo img {
    display: block;
    width: 100%; }
  @media screen and (max-width: 768px) {
    .page__logo {
      margin-top: 0; } }

.fancy--img {
  display: inline-block;
  vertical-align: top;
  border: 8px solid #b2b3b3; }
  .fancy--img img {
    width: 100%; }
  @media screen and (max-width: 420px) {
    .fancy--img {
      width: 100%; } }

/* page warranty */
/* page contacts */
.map {
  height: 440px;
  margin-bottom: 45px; }
  .map iframe {
    height: 100%;
    width: 100%; }
  @media screen and (max-width: 1199px) {
    .map {
      height: 400px; } }
  @media screen and (max-width: 991px) {
    .map {
      height: 350px; } }
  @media screen and (max-width: 768px) {
    .map {
      height: 300px; } }

.cts {
  display: flex;
  flex-wrap: wrap; }
  .cts .cts__left {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 680px;
    padding-top: 67px;
    margin-bottom: 80px; }
  .cts .cts__right {
    width: 455px;
    padding-left: 15px;
    margin-bottom: 30px; }
    .cts .cts__right .cts__info {
      font-family: "FuturaPTMedium";
      text-align: left;
      margin: 0 0 20px 0; }
  .cts .cts__img {
    width: 325px;
    border: 15px solid #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    position: relative; }
    .cts .cts__img img {
      display: block;
      width: 100%;
      border: 2px solid #b3b3b3; }
    .cts .cts__img .img {
      position: absolute;
      top: -15px;
      left: -15px;
      right: -15px;
      bottom: -15px;
      transform: rotate(-8deg);
      border: 15px solid #fff;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
      z-index: -1; }
      .cts .cts__img .img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
  .cts .cts__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-family: "ROMUL";
    padding-right: 50px;
    line-height: 1; }
    .cts .cts__title > span {
      color: #261b17;
      text-align: right; }
      .cts .cts__title > span.cts__title--1 {
        font-size: 38px; }
        .cts .cts__title > span.cts__title--1 span {
          font-size: 49px; }
      .cts .cts__title > span.cts__title--2 {
        font-size: 29px;
        padding-right: 115px;
        margin: 10px 0 5px; }
      .cts .cts__title > span.cts__title--3 {
        font-size: 33px; }
        .cts .cts__title > span.cts__title--3 span {
          font-size: 48px; }
      .cts .cts__title > span.cts__title--4 {
        font-size: 36px;
        padding-right: 176px;
        margin-bottom: -41px;
        margin-top: 12px; }
      .cts .cts__title > span.cts__title--5 {
        font-size: 36px;
        margin-right: -18px; }
        .cts .cts__title > span.cts__title--5 span {
          font-size: 63px; }
  .cts ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .cts ul li {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .cts ul li .icon {
        display: flex;
        width: 42px;
        height: 38px;
        align-items: center;
        border-right: 1px dashed #b9baba;
        margin-right: 18px; }
        .cts ul li .icon img {
          max-width: 90%;
          max-height: 32px;
          object-fit: contain;
          object-position: center center; }
        .cts ul li .icon i {
          font-size: 24px;
          color: #b3b4b4; }
          .cts ul li .icon i.fa-envelope {
            font-size: 20px; }
          .cts ul li .icon i.fa-mobile {
            font-size: 34px;
            margin-left: 2px; }
      .cts ul li p {
        margin-bottom: 0; }
  @media screen and (max-width: 1199px) {
    .cts .cts__left {
      padding-top: 40px;
      margin: 25px auto 40px; }
    .cts .cts__right {
      margin: 0 auto; } }
  @media screen and (max-width: 991px) {
    .cts .cts__left {
      margin-top: 0; }
    .cts .cts__right {
      padding-left: 0; } }
  @media screen and (max-width: 768px) {
    .cts .cts__img {
      width: 275px;
      margin-right: 20px; }
    .cts .cts__left {
      justify-content: center;
      padding-top: 15px; }
    .cts .cts__title > span.cts__title--1 {
      font-size: 26px; }
      .cts .cts__title > span.cts__title--1 span {
        font-size: 38px; }
    .cts .cts__title > span.cts__title--2 {
      font-size: 20px;
      padding-right: 78px;
      margin-top: 5px; }
      .cts .cts__title > span.cts__title--2 span {
        font-size: 38px; }
    .cts .cts__title > span.cts__title--3 {
      font-size: 22px;
      margin-right: -7px; }
      .cts .cts__title > span.cts__title--3 span {
        font-size: 38px; }
    .cts .cts__title > span.cts__title--4 {
      font-size: 28px;
      padding-right: 118px;
      margin-top: 3px; }
    .cts .cts__title > span.cts__title--5 {
      font-size: 28px;
      margin-top: 11px;
      margin-right: -40px; }
      .cts .cts__title > span.cts__title--5 span {
        font-size: 48px; }
    .cts .cts__right {
      width: auto;
      margin: 0 auto; }
      .cts .cts__right .cts__info {
        text-align: center; }
      .cts .cts__right ul {
        display: table;
        margin: 0 auto; } }
  @media screen and (max-width: 580px) {
    .cts .cts__left {
      align-items: center; }
    .cts .cts__img {
      width: 200px; }
    .cts .cts__title {
      padding-right: 25px; }
      .cts .cts__title > span.cts__title--1 {
        font-size: 22px; }
        .cts .cts__title > span.cts__title--1 span {
          font-size: 28px; }
      .cts .cts__title > span.cts__title--2 {
        font-size: 18px;
        padding-right: 64px;
        margin: 5px 0 0 0; }
      .cts .cts__title > span.cts__title--3 {
        font-size: 18px;
        margin-right: 0; }
        .cts .cts__title > span.cts__title--3 span {
          font-size: 28px; }
      .cts .cts__title > span.cts__title--4 {
        font-size: 18px;
        padding-right: 102px;
        margin-bottom: -30px; }
      .cts .cts__title > span.cts__title--5 {
        font-size: 20px;
        margin-right: -9px;
        margin-top: 10px; }
        .cts .cts__title > span.cts__title--5 span {
          font-size: 38px; } }
  @media screen and (max-width: 480px) {
    .cts .cts__left {
      align-items: center; }
    .cts .cts__img {
      width: 150px;
      border: 10px solid #fff; }
      .cts .cts__img .img {
        border: 10px solid #fff; }
    .cts .cts__title {
      padding-right: 0; }
      .cts .cts__title > span.cts__title--1 {
        font-size: 18px; }
        .cts .cts__title > span.cts__title--1 span {
          font-size: 24px; }
      .cts .cts__title > span.cts__title--2 {
        font-size: 14px;
        padding-right: 53px;
        margin: 5px 0 0 0; }
      .cts .cts__title > span.cts__title--3 {
        font-size: 16px; }
        .cts .cts__title > span.cts__title--3 span {
          font-size: 24px; }
      .cts .cts__title > span.cts__title--4 {
        font-size: 14px;
        padding-right: 82px; }
      .cts .cts__title > span.cts__title--5 {
        font-size: 16px;
        margin-top: 12px;
        margin-right: -6px; }
        .cts .cts__title > span.cts__title--5 span {
          font-size: 28px; } }
  @media screen and (max-width: 380px) {
    .cts .cts__img {
      width: 125px;
      margin-right: 15px; } }

/* page contacts */
/* modal */
.modal .close {
  top: 10px;
  right: 12px; }

.modal .modal-dialog {
  top: 45%;
  margin: 0 auto;
  transform: translateY(-50%) !important; }

.modal .modal-content {
  width: auto;
  max-width: 420px;
  border-radius: 0px;
  padding: 15px;
  margin: 0 auto; }
  .modal .modal-content h4 {
    font-family: "FuturaPTBold";
    text-align: center;
    text-transform: uppercase;
    padding: 0 15px;
    margin: 0; }

.modal .modal-body {
  padding: 60px 0px 60px 0;
  border: 1px solid #d1d1d1; }

.modal form {
  display: block;
  padding-bottom: 140px;
  margin-top: 25px; }
  .modal form textarea,
  .modal form input[type="text"],
  .modal form input[type="email"],
  .modal form input[type="search"],
  .modal form input[type="password"] {
    width: 100%;
    background-color: #fff;
    font-family: "GOTHIC";
    font-size: 16px;
    padding: 5px 15px;
    margin-bottom: 15px;
    box-shadow: none; }
  .modal form .g-recaptcha {
    float: right; }
  .modal form .btn {
    float: right;
    margin: 15px 0 0 0; }

.modal#modal--main .modal-dialog {
  top: 45%; }

.modal#modal--main .modal-content {
  max-width: 100%;
  padding: 15px; }

.modal#modal--main .modal-body {
  padding: 30px 20px 0px; }

.modal .cts {
  font-size: 15px; }
  .modal .cts .cts__up {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto; }
    .modal .cts .cts__up img {
      max-width: 190px;
      margin-right: 25px; }
    .modal .cts .cts__up h5 {
      font-size: 16px; }
  .modal .cts .cts__left,
  .modal .cts .cts__right {
    width: 100%;
    z-index: 1; }
  .modal .cts .cts__left {
    align-items: center;
    padding: 0 40px;
    margin-bottom: 70px;
    margin-top: -15px;
    transform: scale(0.75); }
  .modal .cts .cts__right {
    width: 590px;
    padding-left: 0;
    margin-bottom: 0px;
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto; }
  .modal .cts .cts__img {
    width: 280px; }
  .modal .cts .cts__title {
    margin-right: 20px; }
  .modal .cts ul {
    display: flex;
    flex-wrap: wrap; }
    .modal .cts ul li {
      margin-bottom: 10px; }
      .modal .cts ul li:nth-of-type(odd) {
        width: 66%; }

.modal .modal-img {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px 0 20px;
  overflow: hidden; }
  .modal .modal-img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center; }

@media screen and (min-width: 992px) {
  .modal#modal--main .modal-dialog {
    width: 800px; }
    .modal#modal--main .modal-dialog .modal-body {
      height: 495px; } }

@media screen and (max-width: 1280px) {
  .modal#modal--main .modal-body {
    height: 480px !important;
    padding: 35px 75px 0px; }
  .modal .cts .cts__up {
    margin-bottom: 40px; }
  .modal .cts .cts__left {
    padding: 0;
    margin-bottom: 45px;
    margin-top: -50px;
    transform: scale(0.76); }
  .modal .cts .cts__title {
    padding-right: 0; } }

@media screen and (max-width: 991px) {
  .modal#modal--main .modal-dialog {
    width: 100%;
    top: 0;
    transform: translateY(0) !important; }
  .modal#modal--main .modal-content {
    margin: 15px; }
  .modal#modal--main .modal-body {
    padding: 35px 45px 20px; }
  .modal .cts li.cts__hide {
    display: none; }
  .modal .cts .cts__left {
    transform: scale(1);
    padding: 0 70px;
    margin: -20px 0 30px; }
  .modal .cts .cts__right {
    margin: 0 auto; } }

@media screen and (max-width: 767px) {
  .modal .modal-content h4 {
    font-size: 16px; }
  .modal#modal--main .modal-body {
    height: auto !important;
    padding: 35px 15px 20px; }
  .modal .cts .cts__up {
    margin-top: 0px;
    margin-bottom: 0px; }
    .modal .cts .cts__up img {
      width: 45%; }
    .modal .cts .cts__up h5 {
      font-size: 16px; }
  .modal .cts .cts__left {
    padding: 0 15px;
    margin-top: 20px;
    margin-bottom: 20px; }
  .modal .cts .cts__right li {
    width: 50% !important; }
    .modal .cts .cts__right li:nth-of-type(1) {
      order: 1; }
    .modal .cts .cts__right li:nth-of-type(2) {
      order: 3; }
    .modal .cts .cts__right li:nth-of-type(3) {
      order: 2; }
    .modal .cts .cts__right li:nth-of-type(4) {
      order: 4; }
  .modal .modal-img {
    padding: 5px 0 0px; } }

@media screen and (max-width: 480px) {
  .modal .modal-dialog {
    margin: 0 10px; }
    .modal .modal-dialog h4 {
      font-size: 16px; }
  .modal#modal--main .modal-body {
    padding: 35px 10px 25px; }
  .modal .cts .cts__img {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border: 5px solid #fff; }
    .modal .cts .cts__img .img {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      border: 5px solid #fff;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px; }
  .modal .cts .cts__up {
    flex-direction: column;
    text-align: center;
    margin-top: 0px; }
    .modal .cts .cts__up h5 {
      font-size: 12px; }
  .modal .cts .cts__left {
    margin: 10px 0 20px; }
  .modal .cts .cts__right {
    width: 245px;
    font-size: 14px;
    margin: 0 auto; }
    .modal .cts .cts__right ul li {
      width: 100% !important; }
      .modal .cts .cts__right ul li .icon {
        width: 38px;
        margin-right: 10px; }
  .modal .modal-img {
    padding-bottom: 10px; }
  .modal#modal--main .modal-dialog {
    margin: 0; } }

@media screen and (max-width: 380px) {
  .modal .modal-content form .g-recaptcha {
    transform: scale(0.85);
    transform-origin: 100% 50%; }
  .modal .cts__title > span.cts__title--1 {
    font-size: 14px; }
    .modal .cts__title > span.cts__title--1 span {
      font-size: 20px; }
  .modal .cts__title > span.cts__title--2 {
    font-size: 12px;
    padding-right: 38px; }
  .modal .cts__title > span.cts__title--3 {
    font-size: 14px;
    margin-right: -12px; }
    .modal .cts__title > span.cts__title--3 span {
      font-size: 22px; }
  .modal .cts__title > span.cts__title--4 {
    font-size: 12px;
    padding-right: 64px; }
  .modal .cts__title > span.cts__title--5 {
    font-size: 14px;
    margin-right: -13px;
    margin-top: 17px; }
    .modal .cts__title > span.cts__title--5 span {
      font-size: 22px; } }

@media screen and (max-width: 340px) {
  .modal .cts .cts__left {
    padding: 0 0 0 5px; } }

/* modal */
/* page content */
/* footer */
footer {
  font-size: 16px;
  position: relative;
  padding-bottom: 45px;
  margin: 0px 0 0 0; }
  footer:before {
    content: '';
    height: 70px;
    width: 100%;
    background: url(../img/111.svg) repeat-x;
    background-size: contain;
    margin-bottom: 45px; }
  footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  footer .foote__logo {
    display: block;
    width: 75px;
    margin: 0 auto; }
    footer .foote__logo img {
      display: block;
      width: 100%; }
  footer .footer__col {
    text-align: center; }
    footer .footer__col.left {
      width: calc(100% - 365px);
      padding-right: 15px; }
    footer .footer__col.right {
      width: 365px;
      position: relative; }
      footer .footer__col.right img {
        display: block;
        width: 100%; }
    footer .footer__col h5 {
      font-family: "Conv_CALIBRI_0";
      margin: 12px 0; }
      footer .footer__col h5 .line {
        display: inline-block;
        vertical-align: top;
        height: 16px;
        width: 2px;
        background-color: #424141;
        margin: 0 15px; }
    footer .footer__col h4 {
      width: 200px;
      font-size: 18px;
      position: absolute;
      top: 50%;
      left: 40px;
      margin: 0 auto;
      font-family: "GOTHIC";
      color: #ffffff;
      transform: translateY(-50%); }
    footer .footer__col p {
      font-family: "Conv_CALIBRI_0";
      color: #949494;
      margin: 0; }
  @media screen and (max-width: 1199px) {
    footer {
      margin-top: 30px; }
      footer:before {
        height: 50px;
        background-size: cover;
        margin-bottom: 25px; } }
  @media screen and (max-width: 991px) {
    footer {
      padding-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    footer .container {
      flex-direction: column; }
    footer .footer__col.left {
      width: 100%;
      padding: 0;
      margin-bottom: 15px; } }
  @media screen and (max-width: 500px) {
    footer .footer__col.right {
      width: 300px; }
      footer .footer__col.right img {
        width: 100%; }
    footer .footer__col h4 {
      width: 170px;
      font-size: 16px;
      left: 30px; }
    footer .footer__col h5 {
      font-size: 16px; }
      footer .footer__col h5 .line {
        margin: 0px 8px; } }

/* footer */
