/* footer */
footer {
    font-size: 16px;
    position: relative;
    padding-bottom: 45px;
    margin: 0px 0 0 0;
    &:before {
        content: '';
        height: 70px;
        width: 100%;
        background: url(../img/111.svg) repeat-x;
        background-size: contain;
        margin-bottom: 45px;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .foote__logo {
        display: block;
        width: 75px;
        margin: 0 auto;
        img {
            display: block;
            width: 100%;
        }
    }
    .footer__col {
        text-align: center;
        &.left {
            width: calc(100% - 365px);
            padding-right: 15px;
        }
        &.right {
            width: 365px;
            position: relative;
            img {
                display: block;
                width: 100%;
            }
        }
        h5 {
            font-family: $callibri;
            margin: 12px 0;
            .line {
                @include inline-block;
                height: 16px;
                width: 2px;
                background-color: #424141;
                margin: 0 15px;
            }
        }
        h4 {
            width: 200px;
            font-size: 18px;
            position: absolute;
            top: 50%;
            left: 40px;
            margin: 0 auto;
            font-family: $regular;
            color: #ffffff;
            transform: translateY(-50%);
        }
        p {
            font-family: $callibri;
            color: #949494;
            margin: 0;
        }
    }
    @media screen and (max-width: 1199px) {
        margin-top: 30px;
        &:before {
            height: 50px;
            background-size: cover;
            margin-bottom: 25px;
        }
    }
    @media screen and (max-width: 991px) {
        padding-bottom: 20px;
    }
    @media screen and (max-width: 768px) {
        .container {
            flex-direction: column;
        }
        .footer__col {
            &.left {
                width: 100%;
                padding: 0;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .footer__col {
            &.right {
                width: 300px;
                img {
                    width: 100%;    
                }
            }
            h4 {
                width: 170px;
                font-size: 16px;
                left: 30px;
            }
            h5 {
                font-size: 16px;
                .line {
                    margin: 0px 8px;
                }
            }
        }
    }
}
/* footer */