/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'FuturaPTBold';
    src: url('../fonts/FuturaPTBold.eot');
    src: url('../fonts/FuturaPTBold.eot') format('embedded-opentype'),
         url('../fonts/FuturaPTBold.woff2') format('woff2'),
         url('../fonts/FuturaPTBold.woff') format('woff'),
         url('../fonts/FuturaPTBold.ttf') format('truetype'),
         url('../fonts/FuturaPTBold.svg#FuturaPTBold') format('svg');
}
@font-face {
    font-family: 'FuturaPTBook';
    src: url('../fonts/FuturaPTBook.eot');
    src: url('../fonts/FuturaPTBook.eot') format('embedded-opentype'),
         url('../fonts/FuturaPTBook.woff2') format('woff2'),
         url('../fonts/FuturaPTBook.woff') format('woff'),
         url('../fonts/FuturaPTBook.ttf') format('truetype'),
         url('../fonts/FuturaPTBook.svg#FuturaPTBook') format('svg');
}
@font-face {
    font-family: 'FuturaPTMedium';
    src: url('../fonts/FuturaPTMedium.eot');
    src: url('../fonts/FuturaPTMedium.eot') format('embedded-opentype'),
         url('../fonts/FuturaPTMedium.woff2') format('woff2'),
         url('../fonts/FuturaPTMedium.woff') format('woff'),
         url('../fonts/FuturaPTMedium.ttf') format('truetype'),
         url('../fonts/FuturaPTMedium.svg#FuturaPTMedium') format('svg');
}
@font-face {
    font-family: 'GOTHIC';
    src: url('../fonts/GOTHIC.eot');
    src: url('../fonts/GOTHIC.eot') format('embedded-opentype'),
         url('../fonts/GOTHIC.woff2') format('woff2'),
         url('../fonts/GOTHIC.woff') format('woff'),
         url('../fonts/GOTHIC.ttf') format('truetype'),
         url('../fonts/GOTHIC.svg#GOTHIC') format('svg');
}
@font-face {
    font-family: 'GOTHICB';
    src: url('../fonts/GOTHICB.eot');
    src: url('../fonts/GOTHICB.eot') format('embedded-opentype'),
         url('../fonts/GOTHICB.woff2') format('woff2'),
         url('../fonts/GOTHICB.woff') format('woff'),
         url('../fonts/GOTHICB.ttf') format('truetype'),
         url('../fonts/GOTHICB.svg#GOTHICB') format('svg');
}
@font-face {
    font-family: 'ROMUL';
    src: url('../fonts/ROMUL.eot');
    src: url('../fonts/ROMUL.eot') format('embedded-opentype'),
         url('../fonts/ROMUL.woff2') format('woff2'),
         url('../fonts/ROMUL.woff') format('woff'),
         url('../fonts/ROMUL.ttf') format('truetype'),
         url('../fonts/ROMUL.svg#ROMUL') format('svg');
}

@font-face {
    font-family: 'Conv_CALIBRI_0';
    src: url('../fonts/CALIBRI_0.eot');
    src: local('☺'), url('../fonts/CALIBRI_0.woff') format('woff'), 
        url('../fonts/CALIBRI_0.ttf') format('truetype'), 
        url('../fonts/CALIBRI_0.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_CALIBRIB_0';
    src: url('../fonts/CALIBRIB_0.eot');
    src: local('☺'), url('../fonts/CALIBRIB_0.woff') format('woff'), 
        url('../fonts/CALIBRIB_0.ttf') format('truetype'), 
        url('../fonts/CALIBRIB_0.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ar_essenceregular';
    src: url('../fonts/aressence-webfont.eot');
    src: url('../fonts/aressence-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aressence-webfont.woff2') format('woff2'),
         url('../fonts/aressence-webfont.woff') format('woff'),
         url('../fonts/aressence-webfont.ttf') format('truetype'),
         url('../fonts/aressence-webfont.svg#ar_essenceregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


$regular: 'GOTHIC';
$bold: 'FuturaPTBold';
$book: 'FuturaPTBook';
$medium: 'FuturaPTMedium';
$boldG: 'GOTHICB';
$romul: 'ROMUL';
$callibri: 'Conv_CALIBRI_0';
$callibriB: 'Conv_CALIBRIB_0';
$ar: 'ar_essenceregular';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
    min-width: 300px;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $book;
    color: #261b17;
	margin: 0;
    &.load {
        a {
            transition: 300ms;
        }
    }
    @media screen and (max-width: 991px) {
        padding-top: 100px;
    }
    @media screen and (max-width: 580px) {
        padding-top: 80px;
    }
}

h1 {
	font-family: $regular;
	font-size: 42px;
	text-transform: uppercase;
	text-align: center;
	color: #261b17;
	line-height: 1;
	margin: 0 0 40px;
    a {
        font-size: 42px;
    }
	@media screen and (max-width: 1199px) {
		font-size: 32px;
        a {
            font-size: 32px;
        }
	}
	@media screen and (max-width: 991px) {
		font-size: 28px;
        margin-bottom: 30px;
        a {
            font-size: 28px
        }
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 20px;
        a {
            font-size: 24px;
        }
    }
}
h2 {
	font-family: $regular;
	font-size: 36px;
	color: #261b17;
    text-transform: uppercase;
    margin: 0 0 40px;
	a {
        font-size: 36px;
	}
	@media screen and (max-width: 1199px) {
		font-size: 30px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 24px;
        margin-bottom: 30px;
		a {
			font-size: 24px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 20px;
        a {
            font-size: 22px;
        }
    }
}
h3 {
	font-family: $medium;
	font-size: 24px;
	color: #261b17;
	@media screen and (max-width: 991px) {
		font-size: 22px;
	}
    @media screen and (max-width: 20px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $medium;
	font-size: 20px;
	color: #261b17;
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $medium;
	font-size: 18px;
	color: #261b17;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #261b17;
}
a {
    color: #261b17;
	text-decoration: none;
	&:focus {
		color: #261b17;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #ff181f;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
    font-size: 18px;
    line-height: 1.2;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        width: 100%;
		background-color: #fff;
		font-family: $book;
		font-size: 18px;
		padding: 0;
        margin-bottom: 15px;
        border: none;
        border-bottom: 1px solid #8f8f8f;
        box-shadow: none;
        outline: none;
        &::placeholder {
            color: #7f7f7f;
        }
	}
    input[name="tel"] {
        border: none;
    }
}
.main {
    padding-bottom: 50px;
    @media screen and (max-width: 1199px) {
        padding-bottom: 0;
    }
}
.close {
    position: absolute;
    right: 20px;
    top: 18px;
    font-family: $callibri;
    font-size: 16px;
    color: #8c8c8b;
    opacity: 1;
    outline: none;
    padding-right: 27px;
    transition: 300ms;
    &:before {
        content: '';
        width: 15px;
        height: 15px;
        background: url(../img/close.png) no-repeat center center;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
.btn {
	display: table;
    height: 40px;
	width: auto!important;
	background-color: #727271!important;
	font-family: $book!important;
	font-size: 18px;
	color: #fff!important;
	cursor: pointer;
	border-radius: 0px;
	border: none;
	padding: 9px 15px 7px!important;
    line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #727271!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #292929!important;
		color: #fff!important;
	}	
}
input[type="submit"].btn {
    padding: 8px 15px 9px!important;
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #304fbf;
		position: absolute;
		top: -2px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
	top: 2px;
}
.green {
    color: #00993d;
}
.red {
    color: #ff181f;
}
.check {
    width: 20px;
    height: 20px;
    background: url(../img/checked.svg) no-repeat center center;
    background-size: contain;
}
.help {
    position: relative;
    @include inline-block;
    &:after {
        content: '?';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        font-family: $callibri;
        font-size: 13px;
        border-radius: 100%;
        background-color: #727271;
        color: #fff;
        cursor: pointer;
    }
    .help__block {
        display: none;
        width: 265px;
        background-color: #e8e8e8;
        font-family: $callibri;
        font-size: 14px;
        color: #292929;
        position: absolute;
        top: -13px;
        left: calc(100% + 14px);
        padding: 10px 15px;
        line-height: 1.2;
        z-index: 1;
        &:after {
            content: '';
            position: absolute;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right: 9px solid #e8e8e8;
            position: absolute;
            top: 15px;
            left: -9px;
        }
        p {
            margin: 0;
        }
    }
    @media screen and (max-width: 480px) {
        .help__block {
            left: auto;
            right: 0;
        }
    }
}
.title--icon {
    position: relative;
    &:after {
        content: '';
        width: 20px;
        height: 20px;
        background: url(../img/icon17.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        left: -32px;
        top: 50%;
        transform: translateY(-50%);
    }
}
/* header */
header {
    position: relative;
    line-height: 1.2;
    z-index: 9;
    
	.container {
        position: relative;
        min-height: 137px;
    }

    ul {
        @include ul-default;
    }

	/* header logo */
	.logo {
        @include inline-block;
        width: 320px;
        height: 100px;
        margin-top: 20px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        
        }
    }
	/* header logo */

    .header__text {
        display: block;
        width: 600px;
        font-family: $callibriB;
        font-size: 16px;
        color: #000;
        text-align: right;
        position: absolute;
        right: 15px;
        top: 20px;
    }

	/* header nav */
	.nav {
        @include inline-block;
        max-width: 780px;
        float: right;
        margin-top: 77px;
        line-height: 1;
        > ul {
            display: flex;
            flex-wrap: wrap;
            > li {
                position: relative;
                &:after {
                    content: '';
                    width: 2px;
                    height: 100%;
                    background: url(../img/line.jpg) no-repeat center center;
                    background-size: cover;
                    position: absolute;
                    right: -1px;
                    top: 0;
                    z-index: 1;
                }
                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
                > a {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    max-width: 160px;
                    background-color: #000;
                    font-family: $callibri;
                    font-size: 16px;
                    color: #fff;    
                    text-align: center;        
                    padding: 5px 15px;
                    img {
                        max-width: 22px;
                        height: 22px;
                        object-fit: contain;
                        object-position: center center;
                        margin-right: 4px;
                    }
                    &:hover {
                        background-color: #f31d23;
                    }
                }
                &.active > a {
                    background-color: #f31d23;
                    color: #fff;
                }
                &.home {
                    > a {
                        width: 42px;
                        padding-left: 10px;
                        padding-right: 10px;
                        img {
                            margin: 0;
                        }
                    }
                }
                &.like {
                    > a {
                        background-color: #f31d23;
                        padding-left: 10px;
                        padding-right: 10px;
                        &:hover {
                            background-color: #000;
                        }
                        img {
                            margin: 0;
                        }
                    }
                    &.active > a {
                        background-color: #2b2a29;
                    }
                }
                ul {
                    display: none;
                    background-color: #000;
                    position: relative;
                    padding: 5px;
                    li {
                        text-align: center;
                        margin: 7px 0;
                        a {
                            font-family: $callibri;
                            font-size: 16px;
                            color: #fff;
                            &:hover {
                                color: #fff;
                            }
                        }
                        &.active a {
                            color: #f31d23;
                        }
                    }
                }
            }
        }
    }
	/* header nav */

    @media screen and (max-width: 1199px) {
        .logo {
            width: 200px;
        }
    }

    @media screen and (min-width: 992px) {
        .nav {
            > ul {
                > li {
                    &:nth-of-type(3) {
                        > a {
                            padding-left: 16px;
                        }
                    }
                }
            }
        }
    }
	
	@media screen and (max-width: 991px) {
        height: 100px;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
        .container {
            min-height: 100px;
        }
        .logo {
            display: block;
            width: 180px;
            height: 60px;
            margin: 4px auto 0;
        }
        .header__text {
            font-size: 14px;
        }
        .nav {
            display: none;
            max-width: 100%;
            max-height: calc(100% - 100px);
            position: fixed;
            top: 100px;
            left: 0;
            right: 0;
            margin: 0;
            float: none;
            overflow: auto;
            z-index: 1;
            box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
            > ul {
                display: block;
                > li {
                    display: block;
                    border-bottom: 1px solid #fff;
                    &:after {
                        display: none;
                    }
                    &.home {
                        > a {
                            width: 100%;
                        }
                    }
                    &:nth-of-type(3) > a {
                        max-width: initial;
                    }
                    > a {
                        max-width: initial;
                        justify-content: center;
                        > br {
                            display: none;
                        }
                    }
                }
            }
        }
        .header__text {
            width: auto;
            top: 67px;
            left: 10px;
            right: 10px;
            font-size: 12px;
            text-align: center;
            line-height: 1;
        }
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 10px;
			top: 50%;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			transform: translateY(-50%);
            span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #e31e23;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
    @media screen and (max-width: 580px) {
        height: 80px;
        .container {
            min-height: 80px;
        }
        .logo {
            height: 50px;
        }
        .header__text {
            width: 320px;
            font-size: 9px;
            left: 0;
            right: 0;
            top: 58px;
            margin: 0 auto;
        }
        .nav {
            max-height: calc(100% - 80px);
            top: 80px;
        }
    }
    @media screen and (max-width: 420px) {
        .nav--btn {
            top: 40%;
        }
    }
}
/* header */