/* page content */
.page {
    padding: 20px 0 50px 0;
	.news1, .news {
		.date {
			color: #ff181f;
		}
	}
	.page_nav {
		.active {
			color: #ff181f;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #ff181f;
    }
    .back_link {
        margin: 25px 0;
    }
    &.page1 {
        padding-bottom: 20px;
    }
    &.page-advantages {
        .section--video {
            margin-top: 40px;
        }
    }
    @media screen and (max-width: 1199px) {
        padding-bottom: 30px;
        &.page-advantages .section--video {
            margin-top: 20px;
        }
        &.page1 {
            padding-bottom: 0px;
        }
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 991px) {
        padding-bottom: 20px;
    }
    @media screen and (max-width: 500px) {
        &.page-advantages .section--video {
            margin-top: 10px;
        }
    }
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
        &.page-advantages .advantages--text {
            padding: 20px 0 15px;
        }
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #3361eb;
			@include inline-block;
			a {
				text-decoration: none;
			}
			&:after {
				content: '/';
				margin: 0px 2px 0px 7px;
				color: #282727;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
}
/* breadcrumbs */

/* page catalog */
.cat__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .cat__col {
        width: calc(33.33% - 30px);
        height: 240px;
        position: relative;
        margin: 0 15px 30px;
        &:nth-last-child(-n+3) {
            margin-bottom: 0;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        .cat__title {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(43, 42,41, 0.7);
            font-family: $book;
            font-size: 36px;
            text-align: center;
            color: #fff;
            line-height: 1.2;
            opacity: 0;
            transition: 300ms;
            transform: scale(0.5);
        }
        &:hover {
            .cat__title {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .cat__col {
            height: 190px;
        }
    }
    @media screen and (max-width: 991px) {
        margin: 0 -10px;
        .cat__col {
            width: calc(33.33% - 20px);
            margin: 0 10px 20px;
        }
    }
    @media screen and (max-width: 768px) {
        .cat__col {
            .cat__title {
                font-size: 28px;
                line-height: 1;
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cat__col {
            width: calc(50% - 20px);
            .cat__title {
                font-size: 24px;
            }
        }
    }
    @media screen and (max-width: 540px) {
        .cat__col {
            height: 150px;
            .cat__title {
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        margin: 0;
        .cat__col {
            width: 100%;
            height: 220px;
            margin: 0 0 20px;
            &:nth-last-child(-n+3) {
                margin-bottom: 20px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    @media screen and (max-width: 360px) {
        .cat__col {
            height: 200px;
        }
    }
}

.cat--inside {
    display: flex;
    flex-wrap: wrap;
    &.cat__unit {
        .sidebar {
            padding-bottom: 40px;
        }
    }
    > .left {
        display: flex;
        width: calc(100% - 275px);
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        padding: 25px 35px 0 20px;
        h5 {
            width: 100%;
            margin: 30px 0;
        }
    }
    .cat--inside__col {
        width: calc(33.33% - 40px);
        text-align: center;
        line-height: 1.2;
        position: relative;
        margin: 0 20px 20px;
        &:nth-last-child(-n+3) {
            margin-bottom: 0;
        }
        .cat--inside__col--img {
            height: 240px;
            width: 240px;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        p {
            margin: 0px 0px 5px 0;
        }
        .price {
            display: table;
            font-family: $callibriB;
            font-weight: bold;
            font-size: 16px;
            color: #2b2a29;
            margin: 0 auto;
        }
        .old--price {
            text-decoration: line-through;
        }
    }
    .cat--inside__block {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 80px;
        form {
            width: 470px;
            margin-right: 18px;
            input[type="text"],
            input[type="emal"],
            textarea {
                margin: 0;
            }
            .g-recaptcha {
                margin: 15px 0;
            }
        }
        table {
            width: 100%;
            tr {
                td {
                    padding: 7px 15px;
                    border: 1px dashed #c1c2c2;
                    vertical-align: top;
                    &:nth-of-type(1) {
                        font-family: $book;
                        font-size: 18px;
                        white-space: nowrap;
                        padding-right: 25px;
                    }
                    h5 {
                        font-family: $book;
                        color: #949494;
                        margin: 0 0 18px;
                    }
                    p {
                        color: #949494;
                        line-height: 1.3;
                    }
                }
            }
        }
    }
    &.cat--inside__full {
        margin-bottom: 35px;
        .cat--inside__col {
            width: calc(25% - 40px);
        }
    }
    // @media screen and (min-width: 1200px) {
    //     .sidebar.fix {
    //         position: fixed;
    //         right: 50%;
    //         top: 15px;
    //         margin-right: -570px;
    //         max-height: 100%;
    //         overflow: auto;
    //         &.bottom {
    //             position: absolute;
    //             top: auto;
    //             bottom: 15px;
    //         }
    //     }
    // }
    @media screen and (max-width: 1199px) {
        .cat--inside__col {
            .cat--inside__col--img {
                width: 100%;
            }
        }
        .cat--inside__block {
            width: 100%;
            flex-wrap: wrap;
            padding-right: 0;
            form {
                width: 100%;
                margin-right: 0;
                table {
                    width: 100%;
                }
            }
            .info--block {
                margin-top: 25px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    @media screen and (max-width: 991px) {
        > .left {
            width: 100%;
            padding: 0;
        }
        &.sidebar-reverse {
            .sidebar {
                order: 1;
                margin: 0 0 15px;
            }
            > .left {
                order: 2;
            }
        }
        .cat--inside__col {
            .cat--inside__col--img {
                height: 200px;
            }
        }
        .cat--inside__block {
            margin-top: 35px;
            table tr td {
                &:nth-of-type(1) {
                    font-size: 16px;
                }
                h5 {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        &.cat--inside__full {
            margin-bottom: 10px;
            .cat--inside__col {
                width: calc(33.33% - 30px);
                margin: 0 15px 30px;
                &:nth-last-child(-n+3) {
                    margin-bottom: 30px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cat--inside__col {
            width: calc(50% - 30px);
            margin: 0 15px 15px;
            &:nth-last-child(-n+3) {
                margin-bottom: 15px;
            }
        }
        &.cat--inside__full {
            .cat--inside__col {
                width: calc(50% - 30px);
                margin: 0 15px 15px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .cat--inside__col {
            width: calc(50% - 15px);
            margin: 0 7.5px 15px;
            .cat--inside__col--img {
                height: 175px;
            }
        }
        &.cat--inside__full {
            .cat--inside__col {
                width: calc(50% - 15px);
                margin: 0 7.5px 15px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .cat--inside__block table tr td {
            padding: 5px 10px;
        }
    }
    @media screen and (max-width: 340px) {
        .cat--inside__block form .g-recaptcha {
            transform: scale(0.9);
            transform-origin: 0 50%;
        }
    }
}
.back--btn {
    @media screen and (max-width: 768px) {
        margin: 0 auto;
    }
}
.star {
    font-size: 18px;
    color: #ff181f;
}
.filter {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 0 0 35px 0;
    ul {
        @include ul-default;
        li {
            @include inline-block;
            margin: 0 25px 0 0;
            a {
                display: block;
                font-size: 18px;
                position: relative;
                padding-left: 12px;
                &:after {
                    content: '';
                    width: 8px;
                    height: 17px;
                    background: url(../img/arrow--up.png) no-repeat center center;
                    background-color: contain;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
            &.active a {
                color: #ff181f;
            }
            &.filter__down {
                a:after {
                    transform: translateY(-50%) rotate(-180deg);
                }
            }
        }
    }
    @media screen and (max-width: 420px) {
        ul li {
            margin-right: 10px;
            a {
                font-size: 16px;
            }
        }
    }
    @media screen  and (max-width: 340px) {
        ul li {
            a {
                font-size: 14px;
            }
        }
    }
}
.sidebar {
    width: 275px;
    padding-left: 30px;
    border-left: 1px dashed #b2b3b3;
    h5 {
        display: block;
        width: auto;
        font-family: $medium;
        font-size: 19px;
        text-align: center;
        padding-top: 32px;
        margin: 0 0px 15px 0;
        position: relative;
        &:after {
            content: '';
            width: 20px;
            height: 20px;
            background: url(../img/icon17.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            margin: 0 auto;
            transform: translateY(0);
        }
    }
    ul {
        @include ul-default;
        margin-bottom: 30px;
        li {
            position: relative;
            padding-left: 35px;
            margin: 5px 0;
            .icon {
                width: 22px;
                height: 22px;
                position: absolute;
                top: -4px;
                left: 0;
                &.icon1 {
                    width: 22px;
                }
                img {
                    max-width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center left;
                    margin: 0!important;
                }
            }
            a {
                display: table;
                font-size: 16px;
                text-transform: uppercase;
            }
            &.active a {
                color: #ff181f;
            }
        }
        &.sidebar__down {
            li .icon {
                max-width: 15px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        padding: 25px 0 0 0;
        border: none;
        border-top: 1px dashed #b2b3b3;
        text-align: center;
        margin-top: 15px;
        h5 {
            margin-right: 0;
        }
        ul {
            max-width: 300px;
            margin: 0 auto 20px;
        }
        .btn--like {
            margin: 0 auto;
        }
    }
}
.shares {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #f31d23;
    border-radius: 100%;
    font-family: $ar;
    font-size: 20px;
    color: #fff;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
}    
.stiker {
    position: absolute;
    right: -20px;
    top: 0px;
    img {
        margin-bottom: 5px;
    }
    @media screen and (max-width: 768px) {
        right: 0;
    }
}
.btn--like {
    display: table;
    background-color: #f31d23;
    position: relative;
    font-family: $medium;
    font-size: 18px;
    text-transform: uppercase;
    color: #fefefe;
    padding: 10px 15px 10px 45px;
    border-radius: 3px;
    margin: 0;
    line-height: 1;
    &:after {
        content: '';
        width: 19px;
        height: 17px;
        background: url(../img/he-2.svg) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
    }
    &:focus {
        color: #fff;
    }
    &:hover {
        color: #fff;
        opacity: 0.7;
    }
}
.like--title {
    display: block;
    width: 100%;
    font-family: $medium;
    font-size: 24px;
    color: #f31d23;
    text-transform: uppercase;
    position: relative;
    padding: 0 0 0 30px;
    margin-bottom: 25px;
    &:after {
        content: '';
        width: 25px;
        height: 20px;
        background: url(../img/he.svg) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
}

.cat--unit {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .cat--unit__left {
        display: flex;
        width: calc(100% - 135px);
        justify-content: center;
    }
    .cat--unit__right {
        width: 135px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .cat--unit__right--block {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            a {
                overflow: hidden;
                max-width: 100px;
                max-height: 100px;
                margin-bottom: 18px;
                img {
                    height: 100px;
                    width: 100px;
                }
            }
            img {
                display: block;
                width: 100px;
                height: 100px;
                object-fit: contain;
                object-position: center center;
            }
        }
    }
    .cat--unit__down {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: 30px 0 0px;
        h5 {
            margin: 0 0 5px;
        }
        ul {
            @include ul-default;
        }
        .help {
            margin: 4px 0 0 8px;
        }
    }
    .cat--unit--left {
        max-width: 50%;
    }
    .cat--unit--right {
        max-width: 323px;
        margin-right: 0px;
    }
    .delivery__table {
        margin: 10px 0 0px;
    }
    .info--block {
        margin-bottom: 27px;
    }
    .cat--unit__check,
    .cat--unit__like {
        padding-left: 32px;
        position: relative;
        .icon {
            width: 25px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            img {
                display: block;
                max-width: 100%;
                margin: 0!important;
            }
        }
    }
    .cat--unit__check {
        font-family: $callibri;
        line-height: 1.2;
        margin-bottom: 10px;
    }
    .cat--unit__like {
        font-size: 18px;
        color: #ff181f;
        text-transform: uppercase;
        .icon {
            width: 20px;
            height: 20px;
            transition: 300ms;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
            }
            &.hd {
                opacity: 0;
            }
        }
        &.active {
            .icon {
                &.vs {
                    opacity: 0;
                }
                &.hd {
                    opacity: 1;
                }
            }
        }
        
    }
    @media screen and (max-width: 991px) {
        .cat--unit__down .help {
            margin-top: 2px;
        }
    }
    @media screen and (min-width: 769px) {
        .cat--unit__like {
            &:hover {
                .icon {
                    &.vs {
                        opacity: 0;
                    }
                    &.hd {
                        opacity: 1;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat--unit__down {
            .cat--unit--left {
                width: 100%;
                max-width: 100%;
            }
        }
        .cat--unit--right {
            margin: 15px auto;
        }
        .cat--unit__left {
            width: calc(100% - 100px);
        }
        .cat--unit__right {
            width: 100px;
            .cat--unit__right--block {
                a {
                    display: block;
                    width: 75px;
                    height: 75px;
                }
                img {
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cat--unit__left {
            width: 100%;
            padding-right: 0px;
            padding-left: 74px;
        }
        .cat--unit__right {
            display: none;
            width: 50px;
            .cat--unit__right--block {
                a {
                    width: 50px;
                    height: 50px;
                    img {
                        width: 50px;
                        height: 50px;
                    }
                }
                img {
                    width: 50px;
                    height: 50px;
                }
            }
        }
        .cat--unit__down {
            .cat--unit--left {
                .help--block {
                    position: relative;
                }
                .help {
                    position: initial;
                }
                ul li {
                    position: relative;
                    .help {
                        position: initial;
                        .help__block {
                            top: 100%;
                            left: 0;
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 360px) {
        .cat--unit__left {
            padding-left: 68px;
        }
    }
    @media screen and (max-width: 340px) {
        .cat--unit__left {
            padding-left: 66px;
            justify-content: flex-end;
        }
    }
}
.cat--unit__for {
    width: calc(100% - 275px);
    transition: 300ms;
    margin-left: 85px;
    opacity: 0;
    &.slick-initialized {
        opacity: 1;
    }
    .slick-slide {
        height: 400px;
        outline: none;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }
    @media screen and (max-width: 1200px) {
        width: calc(100% - 170px);
        margin-left: 50px;
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: 350px;
        }
    }
    @media screen and (max-width: 480px) {
        width: 240px;
        padding-left: 0px;
        margin-left: 0;
        .slick-slide {
            height: 240px;
            img {
                object-fit: cover;
            }
        }
    }
    @media screen and (max-width: 360px) {
        width: 220px;
        .slick-slide {
            height: 220px;
        }
    }
    @media screen and (max-width: 340px) {
        width: 210px;
        .slick-slide {
            height: 210px;
        }
    }
} 
#glass {
    box-shadow: inset 0px 0px 25px rgba(0,0,0,0.5);
    @media screen and (max-width: 768px) {
        width: 100px!important;
        height: 100px!important;
    }
    @media screen and (max-width: 480px) {
        width: 75px!important;
        height: 75px!important;
    }
}
.cat--unit__nav {
    width: 120px;
    height: 400px;
    transition: 300ms;
    opacity: 0;
    &.slick-initialized {
        opacity: 1;
    }
    .slick-slide {
        height: 120px;
        outline: none;
        margin: 0px 0px 20px;
        opacity: 0.6;
        transition: 300ms;
        border: none;
        cursor: pointer;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        &.slick-current,
        &:hover {
            opacity: 1;
        }
    }
    @media screen and (max-width: 768px) {
        width: 100px;
        height: 350px;
        .slick-slide {
            height: 100px;
        }
    }
    @media screen and (max-width: 480px) {
        width: 74px;
        height: 240px;
        position: absolute;
        top: 20px;
        left: 15px;
        .slick-slide {
            height: 74px;
            margin: 0 0 9px;
        }
    }
    @media screen and (max-width: 360px) {
        width: 68px;
        height: 220px;
        .slick-slide {
            height: 68px;
            margin-bottom: 8px;
        }
    }
    @media screen and (max-width: 340px) {
        width: 66px;
        .slick-slide {
            height: 66px;
            margin-bottom: 6px;
        }
    }
}
.delivery__table {
    width: 100%;
    table {
        width: 100%;
        line-height: 1;
        tr {
            th {
                background-color: #c5c6c6;
                border: 1px solid #c5c6c6;
                padding: 8px 14px;
                font-weight: normal;
            }
            td {
                padding: 8px 14px;
                border: 1px dashed #bfc0c0;
                .check {
                    margin: 0 auto;
                }
            }
        }
    }
    @media screen and (max-width: 380px) {
        table {
            tr {
                th, td {
                    padding: 5px 10px;
                }
            }
        }
    }
}
/* page catalog */

/* page delivery */
.delivery {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .left {
        width: 480px;
        margin-bottom: 25px;
    }
    .right {
        width: calc(100% - 480px)!important;
    }
    .delivery__block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding-left: 35px;
        margin-bottom: 35px;
    }
    .delivery__left {
        width: calc(100% - 495px);
        margin: 15px 0;
    }
    .delivery__table {
        width: 460px;
        margin: 15px 0 15px;
        tr {
            th {
                &:last-of-type {
                    width: 125px;
                    text-align: center;
                }
            }
            td {
                &:last-of-type {
                    text-align: center;
                }
            }
        }
    }
    img {
        display: block;
        max-width: 100%;
    }
    h3 {
        text-transform: uppercase;
        margin: 0 0 10px;
    }
    @media screen and (max-width: 991px) {
        .left, .right, .delivery__left, .delivery__table {
            width: 100%!important;
        }
        .delivery__block {
            padding-left: 0;
            .title--icon {
                padding-left: 25px;
                &:after {
                    left: 0;
                }
            }
        }
        .delivery__table {
            margin-top: 0;
        }
    }
    @media screen and (max-width: 380px) {
        .delivery__table {
            table tr {
                td:nth-of-type(1) {
                    min-width: 140px;
                }
            }
        }
    }
}
.flag {
    text-align: center;
    padding: 0 0 35px;
    img {
        max-width: 100%;
        height: 15px;
    }
}
/* page delivery */

/* page warranty */
.banner {
    margin-bottom: 40px; 
    border-bottom: 1px dashed #d9d9d9;
    position: relative;
    margin-top: 20px;
    &.banner--item {
        border: none;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.2);
            opacity: 0;
        }
    }
    img {
        display: block;
        width: 100%;
    }
    h5 {
        display: flex;
        align-items: center;
        min-height: 100px;
        min-width: 460px;
        max-width: 460px;
        background-color: rgba(255,255,255, 0.3);
        font-family: $book;
        font-size: 47px;
        color: #fff;
        text-transform: uppercase;
        position: absolute;
        bottom: 80px;
        right: 0;
        padding: 10px 20px 10px 40px;
        z-index: 1;
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 25px;
        h5 {
            font-size: 28px;
            bottom: 50px;
        }
    }
    @media screen and (max-width: 580px) {
        h5 {
            min-width: 0px;
            min-height: 0px;
            font-size: 24px;
            padding-left: 20px;
            margin: 0;
            bottom: 25px;
        }
    }
    @media screen and (max-width: 380px) {
        h5 {
            font-size: 22px;
        }
    }
}
.page__logo {
    display: block;
    width: 100px;
    margin: 15px auto 25px;
    img {
        display: block;
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        margin-top: 0;
    }
}
.fancy--img {
    @include inline-block;
    border: 8px solid #b2b3b3;
    img {
        width: 100%;
    }
    @media screen and (max-width: 420px) {
        width: 100%;
    }
}

/* page warranty */

/* page contacts */
.map {
    height: 440px;
    margin-bottom: 45px;
    iframe {
        height: 100%;
        width: 100%;
    }
    @media screen and (max-width: 1199px) {
        height: 400px;
    }
    @media screen and (max-width: 991px) {
        height: 350px;
    }
    @media screen and (max-width: 768px) {
        height: 300px;
    }
}
.cts {
    display: flex;
    flex-wrap: wrap;
    .cts__left {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 680px;
        padding-top: 67px;
        margin-bottom: 80px;
    }
    .cts__right {
        width: 455px;
        padding-left: 15px;
        margin-bottom: 30px;
        .cts__info {
            font-family: $medium;
            text-align: left;
            margin: 0 0 20px 0;
        }
    }
    .cts__img {
        width: 325px;
        border: 15px solid #fff;
        box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
        position: relative;
        img {
            display: block;
            width: 100%;
            border: 2px solid #b3b3b3;
        }
        .img {
            position: absolute;
            top: -15px;
            left: -15px;
            right: -15px;
            bottom: -15px;
            transform: rotate(-8deg);
            border: 15px solid #fff;
            box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
            z-index: -1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
    .cts__title {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-family: $romul;
        padding-right: 50px;
        line-height: 1;
        > span {
            color: #261b17;
            text-align: right;
            &.cts__title--1 {
                font-size: 38px;
                span {
                    font-size: 49px;
                }
            }
            &.cts__title--2 {
                font-size: 29px;
                padding-right: 115px;
                margin: 10px 0 5px;
            }
            &.cts__title--3 {
                font-size: 33px;
                span {
                    font-size: 48px;
                }
            }
            &.cts__title--4 {
                font-size: 36px;
                padding-right: 176px;
                margin-bottom: -41px;
                margin-top: 12px;
            }
            &.cts__title--5 {
                font-size: 36px;
                margin-right: -18px;
                span {
                    font-size: 63px;
                }
            }
        }
    }
    ul {
        @include ul-default;
        li {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .icon {
                display: flex;
                width: 42px;
                height: 38px;
                align-items: center; 
                border-right: 1px dashed #b9baba;
                margin-right: 18px;
                img {
                    max-width: 90%;
                    max-height: 32px;
                    object-fit: contain;
                    object-position: center center;
                }
                i {
                    font-size: 24px;
                    color: #b3b4b4;
                    &.fa-envelope {
                        font-size: 20px;
                    }
                    &.fa-mobile {
                        font-size: 34px;
                        margin-left: 2px;
                    }
                }
            }
            p {
                margin-bottom: 0;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .cts__left {
            padding-top: 40px;
            margin: 25px auto 40px;
        }
        .cts__right {
            margin: 0 auto;
        }
    }
    @media screen and (max-width: 991px) {
        .cts__left {
            margin-top: 0;
        }
        .cts__right {
            padding-left: 0;
        }
    }
    @media screen and (max-width: 768px) {
        .cts__img {
            width: 275px;
            margin-right: 20px;
        }
        .cts__left {
            justify-content: center;
            padding-top: 15px;
        }
        .cts__title {
            > span {
                &.cts__title--1 {
                    font-size: 26px;
                    span {
                        font-size: 38px;
                    }
                }
                &.cts__title--2 {
                    font-size: 20px;
                    padding-right: 78px;
                    margin-top: 5px;
                    span {
                        font-size: 38px;
                    }
                }
                &.cts__title--3 {
                    font-size: 22px;
                    margin-right: -7px;
                    span {
                        font-size: 38px;
                    }
                }
                &.cts__title--4 {
                    font-size: 28px;
                    padding-right: 118px;
                    margin-top: 3px;
                }
                &.cts__title--5 {
                    font-size: 28px;
                    margin-top: 11px;
                    margin-right: -40px;
                    span {
                        font-size: 48px;
                    }
                }
            }
        }
        .cts__right {
            width: auto;
            margin: 0 auto;
            .cts__info {
                text-align: center;
            }
            ul {
                display: table;
                margin: 0 auto;
            }
        }
    }
    @media screen and (max-width: 580px) {
        .cts__left {
            align-items: center;
        }
        .cts__img {
            width: 200px;
        }
        .cts__title {
            padding-right: 25px;
            > span {
                &.cts__title--1 {
                    font-size: 22px;
                    span {
                        font-size: 28px;
                    }
                }
                &.cts__title--2 {
                    font-size: 18px;
                    padding-right: 64px;
                    margin: 5px 0 0 0;
                }
                &.cts__title--3 {
                    font-size: 18px;
                    margin-right: 0;
                    span {
                        font-size: 28px;
                    }
                }
                &.cts__title--4 {
                    font-size: 18px;
                    padding-right: 102px;
                    margin-bottom: -30px;
                }
                &.cts__title--5 {
                    font-size: 20px;
                    margin-right: -9px;
                    margin-top: 10px;
                    span {
                        font-size: 38px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cts__left {
            align-items: center;
        }
        .cts__img {
            width: 150px;
            border: 10px solid #fff;
            .img {
                border: 10px solid #fff;
            }
        }
        .cts__title {
            padding-right: 0;
            > span {
                &.cts__title--1 {
                    font-size: 18px;
                    span {
                        font-size: 24px;
                    }
                }
                &.cts__title--2 {
                    font-size: 14px;
                    padding-right: 53px;
                    margin: 5px 0 0 0;
                }
                &.cts__title--3 {
                    font-size: 16px;
                    span {
                        font-size: 24px;
                    }
                }
                &.cts__title--4 {
                    font-size: 14px;
                    padding-right: 82px;
                }
                &.cts__title--5 {
                    font-size: 16px;
                    margin-top: 12px;
                    margin-right: -6px;
                    span {
                        font-size: 28px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 380px) {
        .cts__img {
            width: 125px;
            margin-right: 15px;
        }
    }
}
/* page contacts */

/* modal */
.modal {
    .close {
        top: 10px;
        right: 12px;
    }
    .modal-dialog {
        top: 45%;
        margin: 0 auto;
        transform: translateY(-50%)!important;
    }
    .modal-content {
        width: auto;
        max-width: 420px;
        border-radius: 0px;
        padding: 15px;
        margin: 0 auto;
        h4 {
            font-family: $bold;
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            margin: 0;
        }
    }
    .modal-body {
        padding: 60px 0px 60px 0;
        border: 1px solid #d1d1d1;
    }
    form {
        display: block;
        padding-bottom: 140px;
        margin-top: 25px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            width: 100%;
            background-color: #fff;
            font-family: $regular;
            font-size: 16px;
            padding: 5px 15px;
            margin-bottom: 15px;
            box-shadow: none;
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    &#modal--main {
        .modal-dialog {
            top: 45%;
        }
        .modal-content {
            max-width: 100%;
            padding: 15px;
        }
        .modal-body {
            padding: 30px 20px 0px;
        }
    }
    .cts {
        font-size: 15px;
        .cts__up {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
            img {
                max-width: 190px;
                margin-right: 25px;
            }
            h5 {
                font-size: 16px;
            }
        }
        .cts__left,
        .cts__right {
            width: 100%;
            z-index: 1;
        }
        .cts__left {
            align-items: center;
            padding: 0 40px;
            margin-bottom: 70px;
            margin-top: -15px;
            transform: scale(0.75);
        }
        .cts__right {
            width: 590px;
            padding-left: 0;
            margin-bottom: 0px;
            margin-top: -50px;
            margin-left: auto;
            margin-right: auto;
        }
        .cts__img {
            width: 280px;
        }
        .cts__title {
            margin-right: 20px;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                margin-bottom: 10px;
                &:nth-of-type(odd) {
                    width: 66%;
                }
            }
        }
    }
    .modal-img {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 10px 0 20px;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    @media screen and (min-width: 992px) {
        &#modal--main {
            .modal-dialog {
                width: 800px;
                .modal-body {
                    height: 495px;
                }
            }
        }
    }
    @media screen and (max-width: 1280px) {
        &#modal--main {
            .modal-body {
                height: 480px!important;
                padding: 35px 75px 0px;
            }
        }
        .cts {
            .cts__up {
                margin-bottom: 40px;
            }
            .cts__left {
                padding: 0;
                margin-bottom: 45px;
                margin-top: -50px;
                transform: scale(0.76);
            }
            .cts__title {
                padding-right: 0;
            }
        }
    }
    @media screen and (max-width: 991px) {
        &#modal--main {
            .modal-dialog {
                width: 100%;
                top: 0;
                transform: translateY(0)!important;
            }
            .modal-content {
                margin: 15px;
            }
            .modal-body {
                padding: 35px 45px 20px;
            }
        }
        .cts {
            li.cts__hide {
                display: none;
            }
            .cts__left {
                transform: scale(1);
                padding: 0 70px;
                margin: -20px 0 30px;
            }
            .cts__right {
                margin: 0 auto;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .modal-content {
            h4 {
                font-size: 16px;
            }
        }
        &#modal--main {
            .modal-body {
                height: auto!important;
                padding: 35px 15px 20px;
            }
        }
        .cts {
            .cts__up {
                margin-top: 0px;
                margin-bottom: 0px;
                img {
                    width: 45%;
                }
                h5 {
                    font-size: 16px;
                }
            }
            .cts__left {
                padding: 0 15px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .cts__right {
                li {
                    width: 50%!important;
                    &:nth-of-type(1) {
                        order: 1;
                    }
                    &:nth-of-type(2) {
                        order: 3;
                    }
                    &:nth-of-type(3) {
                        order: 2;
                    }
                    &:nth-of-type(4) {
                        order: 4;
                    }
                }
            }
        }
        .modal-img  {
            padding: 5px 0 0px;
        }
    }
    @media screen and (max-width: 480px) {
        .modal-dialog {
            margin: 0 10px;
            h4 {
                font-size: 16px;
            }
        }
        &#modal--main {
            .modal-body {
                padding: 35px 10px 25px;
            }
        }
        .cts {
            .cts__img {
                box-shadow: 0 0 10px rgba(0,0,0,.3);
                border: 5px solid #fff;
                .img {
                    box-shadow: 0 0 15px rgba(0,0,0,.3);
                    border: 5px solid #fff;
                    top: -5px;
                    right: -5px;
                    bottom: -5px;
                    left: -5px;
                }
            }
            .cts__up {
                flex-direction: column;
                text-align: center;
                margin-top: 0px;
                h5 {
                    font-size: 12px;
                }
            }
            .cts__left {
                margin: 10px 0 20px;
            }
            .cts__right {
                width: 245px;
                font-size: 14px;
                margin: 0 auto;
                ul {
                    li {
                        width: 100%!important;
                        .icon {
                            width: 38px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .modal-img  {
            padding-bottom: 10px;
        }
        &#modal--main {
            .modal-dialog {
                margin: 0;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .modal-content {
            form {
                .g-recaptcha {
                    transform: scale(0.85);
                    transform-origin: 100% 50%;
                }
            }
        }
        .cts__title {
            > span {
                &.cts__title--1 {
                    font-size: 14px;
                    span {
                        font-size: 20px;
                    }
                }
                &.cts__title--2 {
                    font-size: 12px;
                    padding-right: 38px;
                }
                &.cts__title--3 {
                    font-size: 14px;
                    margin-right: -12px;
                    span {
                        font-size: 22px;
                    }
                }
                &.cts__title--4 {
                    font-size: 12px;
                    padding-right: 64px;
                }
                &.cts__title--5 {
                    font-size: 14px;
                    margin-right: -13px;
                    margin-top: 17px;
                    span {
                        font-size: 22px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 340px) {
        .cts .cts__left {
            padding: 0 0 0 5px;
        }
    }
}

/* modal */

/* page content */